@use "~Scss/core" as *;

.nav {
    --fw-nav--color: var(--fw-color-neutral-100);
    --fw-nav--border-color: var(--fw-color-neutral-600);
    --fw-nav--badge-color: var(--fw-color-neutral-900);
    --fw-nav--badge-bg: var(--fw-color-neutral-200);
    --fw-nav-active--color: var(--fw-color-primary-200);
    --fw-nav-active--border-color: var(--fw-color-primary-200);
    --fw-nav-active--badge-color: var(--fw-color-neutral-800);
    --fw-nav-active--badge-bg: var(--fw-color-primary-200);

    .badge {
        display: flex;
        height: 24px;
        min-width: 24px;
        padding: 0 4px;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: var(--fw-border-radius-xs);
        background: var(--fw-nav--badge-bg);
        color: var(--fw-nav--badge-color);
        transition-duration: 250ms;
        transition-timing-function: ease-in;
        transition-property: background-color, opacity, color;
    }


    :global(.fw-nav.fw-nav) {
        border-bottom-color: var(--fw-nav--border-color);

        :global(.fw-nav-link) {
            color: var(--fw-nav--color);
            border-bottom: 2px solid var(--fw-nav--border-color);
            padding: 6px 5px 8px 8px;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            &:is([aria-selected=true], :hover) {
                color: var(--fw-nav-active--color);

                .badge {
                    background-color: var(--fw-nav-active--badge-bg);
                    color: var(--fw-nav-active--badge-color);
                }
            }

            &[aria-selected=true] {
                border-bottom-color: var(--fw-nav-active--border-color);
            }
        }
    }
}
