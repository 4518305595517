@use "mixins" as *;
@use "sass:math" as m;
@import "parts";

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **

  Variants

*** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *
*/

%variant {
    /* defaults */
}

.variant--default {
    @extend %variant;
}

.variant--content {
    @extend %variant;
    > .main > table {
        border-collapse: collapse;
    }
}

.variant--section {
    @extend %variant;
    --head-background-color: white;
}

.variant--section,
.variant--default,
.variant--list {
    --head-background-color: white;

    > .main > table {
        > thead > tr > * {
            border-bottom: var(--head-border-color) solid 2px;
        }
        > tfoot > tr > * {
            border-top: var(--head-border-color) solid 2px;
        }
    }
}

%row-border {
    @extend %variant;
    --table-row-border-color: var(--table-border-color, var(--fw-color-neutral-100));
    --table-row-border-size: var(--table-border-size, 1px);

    > .main > table > .group-body > .row {
        > .cell {
            border-color: var(--table-row-border-color);
            &__first {
                border-left-width: var(--table-row-border-size);
            }
            &__last {
                border-right-width: var(--table-row-border-size);
            }
        }

        &:not(.row__expandable) {
            > .cell {
                border-top-width: var(--table-row-border-size);
                border-bottom-width: var(--table-row-border-size);
            }
        }

        &.row__last > .cell {
            border-bottom-width: var(--table-row-border-size);
        }

        &.row__expanded.row__first > .cell {
            background-color: var(--table-hover-color);
        }
    }
}

.variant--list {
    @extend %row-border;

    --table-spacing: 12px;

    > .main > table > .group-body > .row {
        > .cell {
            --row-padding-y: calc(2 * var(--table-spacing));
            --row-padding-x: var(--table-spacing);
            &.cell__first {
                padding-left: var(--row-padding-y);
            }
            &.cell__last {
                padding-right: var(--row-padding-y);
            }
        }
        &.row__expanded {
            &.row__first > .cell {
            }
        }
    }
}

.variant--accordion {
    @extend %row-border;

    > .main > table > .group-body > .row {
        &:not(.row__expanded) {
            > .cell {
                border-color: transparent;
            }
        }
        &.row__expanded {
            &.row__first > .cell {
                border-bottom-color: transparent;
            }
        }
    }
}


@mixin size(
    $fontSize: 14px,
    $lineHeight: 1.5,
    $height: 47px,
    $headHeight: 56px,
    $paddingX: $fontSize,
    $paddingY: m.ceil(m.div($paddingX, 2))
) {
    --font-size: #{$fontSize};
    --line-height: #{$lineHeight};
    --row-height: #{$height};
    --head-height: #{$headHeight};
    --row-padding-x: #{$paddingX};
    --row-padding-y: #{$paddingY};
}

.size__xs {
    @include size($fontSize: 12px, $height: 30px, $headHeight: 40px);
}

.size__sm {
    @include size($fontSize: 14px, $height: 40px, $headHeight: 50px);
}

.size__lg {
    @include size($height: 56px, $headHeight: 60px);
}
