

.login-image {
    background: url("../../../images/auth/login.jpeg") no-repeat center;
    background-size: cover;
    display: flex;
    height: 100%;
    padding: 48px;
    width: 100%;
}

.ch-flag {
    background: url("../../../images/languages/de.svg") no-repeat center;
}

.us-flag {
    background: url("../../../images/languages/en.svg") no-repeat center;
}
