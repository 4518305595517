.root {
    font-family: var(--font-primary-family);
    width: fit-content;
    background-color: var(--color-dark-navigation-bg);
    color: var( --color-white-fff);
    padding-bottom: 6px;
    min-height: 0;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    :global {
        ::-webkit-scrollbar {
            display: none;
        }
        ::-webkit-scrollbar-track {
            display: none;
        }
        ::-webkit-scrollbar-thumb {
            display: none;
        }
    }
}

.rootShort {
    :global {
        .button-text {
            display: none !important;
        }
    }

    .headerLogo {
        display: none;
    }
    .entirePortfolioText {
        display: none;
    }
    .entirePortfolio {
        padding: 25px 32px;
    }
    .sectionHead {
        padding: 2.5px 16px;
    }
    .sectionItemText {
        display: none;
    }
    .sectionHeadText {
        display: none;
    }
    .sectionHeadDots {
        display: block;
        text-align: center;
        letter-spacing: 0.2em;
        align-items: center;
    }
}

.header {

    padding: 29px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    &Menu {
        cursor: pointer;
    }
}

.navTop {

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.navBody {
    flex: 1;
    padding-top: 1rem;
    min-height: 0;
    overflow-y: auto;
}

.section {
    padding: 12px 16px;

    &Head {
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight: var(--font-primary-weight-semi-bold);
        font-size: 11px;
        line-height: 15px;
        padding: 0px 16px;
        color: var(--color-primary-dark);
        margin: 4px 0px;

        &Text {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &Dots {
            display: none;
        }
    }
    &Item {
        display: flex;
        justify-content: center;
        gap: 16px;
        align-items: center;
        padding: 8px 16px;
        color: var(--color-grey-4);
        font-size: var(--text-regular-size);
        line-height: 150%;
        font-weight: 400;
        border-radius: var(--border-radius-big);

        &[aria-disabled="true"] {
            pointer-events: none;
            color: #d7d8db;
        }
        &:hover {
            color: inherit;
            background-color: var(--color-dark-navigation-light);
        }
        &.active {
            background-color: #4766FF;
            .sectionIcon {
                color: inherit;
            }
            button {
                color: var(--color-grey-4);
            }
        }

        &Text {
            display: flex;
            flex: 1;

            span {
                flex: 1;
            }

            button {
                background: none;
                border: none;
                box-shadow: none;
                padding: 0 16px 0 16px;
                margin: -8px -16px -8px 0;
                color: var(--color-primary-dark);
                &:hover {
                    color: var(--color-white-fff);
                }
            }
        }
    }

    &Icon {
        width: 24px;
        color: var(--color-primary-dark);
    }
}

.dropDown {
    color: var(--color-white-fff);
    background: var(--color-dark-navigation-bg);
    padding: 12px 16px;
    border-radius: var(--border-radius-big);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    opacity: 1;
    transition: opacity 0.1s;

    &.animation_close {
        opacity: 0;
    }
}
