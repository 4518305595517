@use 'sass:list';
@use 'sass:selector' as s;
@use '~Scss/core' as *;

.fw-fade {
    $-transition: opacity 200ms linear;
    &.__show {
        opacity: 0;

        &-active {
            transition: $-transition;
        }
        &-active,
        &-done {
            opacity: 1;
        }
    }

    &.__hide {
        opacity: 1;
        &-active {
            opacity: 0;
            transition: $-transition;
        }

        &-done {
            opacity: 0;
        }
    }
}

.fw-fade2 {
    $-transition: opacity 200ms linear;
    &.__show {
        opacity: 0;
        visibility: hidden;

        &-active {
            transition: $-transition;
        }
        &-active,
        &-done {
            visibility: visible;
            opacity: 1;
        }
    }

    &.__hide {
        opacity: 1;
        visibility: visible;
        &-active {
            opacity: 0;
            visibility: visible;
            transition: $-transition;
        }

        &-done {
            opacity: 0;
            visibility: hidden;
        }
    }
}


.fw-scale {
    //transition-property: transform, width, height, left, right, bottom, top,
    //                     opacity, visibility, background-color, border-color, box-shadow;
    //transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    //transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);

    &-enter {
        opacity: 0;
        transform: scale(0);

        &-active {
            opacity: 1;
            transform: scale(1);
            transition: opacity, transform 200ms ease-in-out;
        }
    }

    &-exit {
        opacity: 1;
        transform: scale(1);

        &-active {
            opacity: 0;
            transform: scale(0);
            transition: opacity, transform 200ms ease-in-out;
        }
    }
}
