.root {
    align-content: center;
}

.btn {
    --notification-color: red;
    --notification-icon-color: var(--notification-color);
    --notification-icon-fill-color: transparent;
    position: relative;
    background: none!important;
    border: none!important;
    padding: 10px!important;
    &:after {
        display: none;
    }

    :global(.show) > &,
    &:hover,
    &:focus,
    &:active {
        --notification-icon-color: var(--notification-color);
        --notification-icon-fill-color: var(--notification-color);
        background: none !important;
        border: none !important;
    }

    :is([aria-busy=true]) &:before {
        display: block;
        content: "";
        position: absolute;
        left: -4px;
        top: -4px;
        width: 48px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 2px solid transparent;
        border-right-color: var(--fw-color-primary-400);
        animation: l2 1s infinite linear;
    }
}

@keyframes l2 {to{transform: rotate(1turn)}}

.menu {
    background: transparent;
    padding: 0;
    margin: 10px 0 0;
    box-shadow: none;
}
