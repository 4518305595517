@use "~Scss/core" as *;

:global {
    #LaunchpadPageEntry {
        position: relative;
        z-index: 10;
        min-width: 0;
        max-width: 750px;
        flex: 1 1 301px;
        padding: 15px 20px;
        background-color: white;

        @include fw-desktop {
            padding: 0;
            margin: 13px 20px;
        }
    }
}

.launchpadDropdownTabsWrapper {
    :global {
        .fw-nav {
            border-bottom-color: #434954;

            a {
                color: #999BA1;

                &:last-child {
                    margin-right: 0;
                }

                &.active, &:hover {
                    color: #AAB9FF !important;
                    border-bottom: 2px solid #AAB9FF !important;
                }

                .active {
                    border-bottom: 2px solid #AAB9FF;
                }

            }
        }
    }
}
