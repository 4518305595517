@use 'sass:list';
@use 'sass:selector' as s;

/* editor specific */
.ck-content {
    border: var(--fw-text-editor__border-size, 1px) solid var(--fw-text-editor__border-color, var(--fw-color-neutral-500)) !important;
    border-radius: 0 0 var(--fw-text-editor__border-radius, 5px) var(--fw-text-editor__border-radius, 5px) !important;
    min-height: 200px;
}

.ck-content.ck-focused {
    border: var(--fw-text-editor__border-size, 1px) solid var(--fw-text-editor__active-color, var(--fw-color-primary-500)) !important;
}

/* document template editor specific */
.ck-editor-document {
    .ck-toolbar > .ck {
        .ck-heading-document_text, .ck-heading-document_title, .ck-heading-document_subtitle {
            font-size: 16px;
        }

        .ck-heading-document_title, .ck-heading-document_subtitle {
            font-weight: 600;
        }

        .ck-heading-document_title {
            border-bottom: 1px solid #9196A1;
        }
    }

    .ck-content {
        ul, ol {
            list-style-position: outside;
            padding: 0 0 0 8mm;

            li {
                margin-bottom: 0;
            }
        }

        p {
            line-height: 16pt;
            font-size: 16px;
        }

        h1, h2 {
            margin: 8mm 0 2mm 0;
            line-height: 16pt;
            font-size: 16px;
            font-weight: 600;
        }

        h1 {
            border-bottom: 1px solid #9196A1;
            padding-bottom: 2mm;
            margin-bottom: 4mm;
        }
    }
}
