

@mixin caret-down($width) {
  border-top: $width solid;
  border-right: $width solid transparent;
  border-bottom: 0;
  border-left: $width solid transparent;
}

@mixin caret-up($width) {
  border-top: 0;
  border-right: $width solid transparent;
  border-bottom: $width solid;
  border-left: $width solid transparent;
}

@mixin caret-right($width) {
  border-top: $width solid transparent;
  border-right: 0;
  border-bottom: $width solid transparent;
  border-left: $width solid;
}

@mixin caret-left($width) {
  border-top: $width solid transparent;
  border-right: $width solid;
  border-bottom: $width solid transparent;
}

@mixin caret($direction: down) {
    --fw-caret-width: .3em;
    --fw-caret-vertical-align: calc(var(--fw-caret-width) * .85);
    --fw-caret-spacing: calc(var(--fw-caret-width) * .85);
    display: inline-block;
    vertical-align: var(--fw-caret-vertical-align);
    content: "";
    $-width: var(--fw-caret-width);
    @if $direction == left {
        margin-right: var(--fw-caret-spacing);

        @include caret-left($-width);
    } @else {
        margin-left: var(--fw-caret-spacing);

        @if $direction == down {
            @include caret-down($-width);
        } @else if $direction == up {
            @include caret-up($-width);
        } @else if $direction == right {
            @include caret-right($-width);
        }
    }
}
