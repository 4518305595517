
$scope: '' !default;


@mixin part($slots...) {
    @if length($slots) > 1 {
        $attrs: ();
        @each $slot in $slots {
            $attrs: append($attrs, '[data-part="#{$slot}"]', comma);
        }

        &:is(#{$attrs}) {
            @content
        }
    }
    @else {
        &[data-part="#{nth($slots, 1)}"] {
            @content
        }
    }
}

@mixin scope($s: $scope) {
    [data-scope="#{$scope}"] {
        @content
    }
}

@mixin slot($slots...) {
    @include scope() {
        @include part($slots...) {
            @content
        }
    }
}
