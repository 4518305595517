$-tooltip-prefix-cls: rc-tooltip;

//
// Tooltips
// --------------------------------------------------
$-overlay-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
//** Tooltip text color
$-tooltip-color: var(--color-white-fff);
//** Tooltip background color
$-tooltip-bg: var(--color-dark-0);
$-tooltip-opacity: 0.9;

$-tooltip-border-width: 1px;
$-tooltip-border-color: $-tooltip-bg;
$-tooltip-shadow-width: 1px;

//** Tooltip arrow width
$-tooltip-arrow-width: 6px;
//** Tooltip distance with trigger
//** Tooltip arrow color
$-tooltip-arrow-color: $-tooltip-border-color;
$-tooltip-arrow-inner-color: $-tooltip-bg;

// Base class
.#{$-tooltip-prefix-cls} {
  position: absolute;
  z-index: 1250;
  display: block;
  visibility: visible;
  padding: $-tooltip-shadow-width;
  opacity: $-tooltip-opacity;

  &-hidden {
    display: none;
  }
}

// Wrapper for the tooltip content
.#{$-tooltip-prefix-cls}-inner {
  padding: 8px 10px;
  color: $-tooltip-color;
  text-align: left;
  text-decoration: none;
  background-color: $-tooltip-bg;
  border-radius: var(--border-radius-standard);
  min-height: 34px;
}

// Arrows
.#{$-tooltip-prefix-cls}-arrow,
.#{$-tooltip-prefix-cls}-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.#{$-tooltip-prefix-cls} {
  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: -$-tooltip-arrow-width + $-tooltip-shadow-width;
    margin-left: -$-tooltip-arrow-width;
    border-width: $-tooltip-arrow-width $-tooltip-arrow-width 0;
    border-top-color: $-tooltip-arrow-color;
  }

  &-placement-top &-arrow-inner,
  &-placement-topLeft &-arrow-inner,
  &-placement-topRight &-arrow-inner {
    bottom: $-tooltip-border-width;
    margin-left: -$-tooltip-arrow-width;
    border-width: $-tooltip-arrow-width $-tooltip-arrow-width 0;
    border-top-color: $-tooltip-arrow-inner-color;
  }

  &-placement-top &-arrow {
    left: 50%;
  }

  &-placement-topLeft &-arrow {
    left: 15%;
  }

  &-placement-topRight &-arrow {
    right: 15%;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: -$-tooltip-arrow-width + $-tooltip-shadow-width;
    margin-top: -$-tooltip-arrow-width;
    border-width: $-tooltip-arrow-width $-tooltip-arrow-width $-tooltip-arrow-width 0;
    border-right-color: $-tooltip-arrow-color;
  }

  &-placement-right &-arrow-inner,
  &-placement-rightTop &-arrow-inner,
  &-placement-rightBottom &-arrow-inner {
    left: $-tooltip-border-width;
    margin-top: -$-tooltip-arrow-width;
    border-width: $-tooltip-arrow-width $-tooltip-arrow-width $-tooltip-arrow-width 0;
    border-right-color: $-tooltip-arrow-inner-color;
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: -$-tooltip-arrow-width + $-tooltip-shadow-width;
    margin-top: -$-tooltip-arrow-width;
    border-width: $-tooltip-arrow-width 0 $-tooltip-arrow-width $-tooltip-arrow-width;
    border-left-color: $-tooltip-arrow-color;
  }

  &-placement-left &-arrow-inner,
  &-placement-leftTop &-arrow-inner,
  &-placement-leftBottom &-arrow-inner {
    right: $-tooltip-border-width;
    margin-top: -$-tooltip-arrow-width;
    border-width: $-tooltip-arrow-width 0 $-tooltip-arrow-width $-tooltip-arrow-width;
    border-left-color: $-tooltip-arrow-inner-color;
  }

  &-placement-left &-arrow {
    top: 50%;
  }

  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: -$-tooltip-arrow-width + $-tooltip-shadow-width;;
    margin-left: -$-tooltip-arrow-width;
    border-width: 0 $-tooltip-arrow-width $-tooltip-arrow-width;
    border-bottom-color: $-tooltip-arrow-color;
  }

  &-placement-bottom &-arrow-inner,
  &-placement-bottomLeft &-arrow-inner,
  &-placement-bottomRight &-arrow-inner {
    top: $-tooltip-border-width;
    margin-left: -$-tooltip-arrow-width;
    border-width: 0 $-tooltip-arrow-width $-tooltip-arrow-width;
    border-bottom-color: $-tooltip-arrow-inner-color;
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }

  &-placement-bottomLeft &-arrow {
    left: 15%;
  }

  &-placement-bottomRight &-arrow {
    right: 15%;
  }
}
