
//
// Global Mixins
//

@mixin fw-attr($attr, $value, $important: '') {
    @if $value != null {
        #{$attr}: #{$value} #{$important};
    }
}

@mixin fw-animate($properties, $duration: 300ms, $timingFunction: ease-in-out) {
    $props: ();
    $duration: unquote($duration);

    @each $p in $properties {
        $props: append($props, $p, comma);
    }

    transition-timing-function: $timingFunction;
    transition-duration: $duration;
    transition-property: $props;
}

@mixin fw-slot($scope, $slots...) {
    @if length($slots) > 1 {
        $attrs: ();
        @each $p in $slots {
            $attrs: append($attrs, '[data-part="#{$p}"]', comma);
        }

        [data-scope="#{$scope}"]:is(#{$attrs}) {
            @content
        }
    }
    @else {
        [data-scope="#{$scope}"][data-part="#{nth($slots, 1)}"] {
            @content
        }
    }
}

@mixin fw-clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

@mixin fw-button-reset() {
    appearance: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    margin: 0;
    padding: 0;
}

@mixin fw-input-reset() {
    border: 0;
    background: none;
    outline: none !important;
    box-shadow: none;
}

// Input placeholder color
@mixin fw-scrollbar() {
    $-scroll-padding: 10px;

    /* width */
    &::-webkit-scrollbar {
        width: 16px + $-scroll-padding;
        background: transparent;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--color-dark-navigation-bg);
        background-clip: padding-box;
        border-left: $-scroll-padding solid transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        &, &:hover {
            background: var(--color-primary-dark);
            background-clip: padding-box;
            border: 4px solid transparent;
            border-left-width: 4px + $-scroll-padding;
        }

        /* Handle on hover */
        &:hover {
            background-color: var(--color-primary-light);
        }
    }
}


// Input placeholder color
@mixin fw-no-scrollbar() {
    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
}



// Input placeholder color
@mixin fw-input-placeholder($color) {
    &::-moz-placeholder {
        color: $color;
        opacity: 1;
    }
    // See https://github.com/twbs/bootstrap/pull/11526
    &:-ms-input-placeholder {
        color: $color;
    }
    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: $color;
    }
    // Safari and Chrome
}



@mixin fw-fix-fixed-position-lags() {
    // webkit hack for smooth font view on fixed positioned elements
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

@mixin fw-fix-animation-lags() {
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
}

@mixin fw-hack-ie {
    //IE10 and IE11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

@mixin fw-hack-edge-all {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}
