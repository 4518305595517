@use 'sass:list';
@use '~Scss/core' as *;
@use '~Scss/core/scoped.scss' as * with (
    $scope: 'layout'
);



// Modal
:root {
    --layout__padding-x: 48px;
    --layout__padding-y: 24px;
}

@include scope() {

    @include part('side', 'main', 'preview') {

    }
}

//
// ROOT
//
@include slot('root') {

}


//
// HEADER
//
@include slot('header') {

}


//
// FOOTER
//
@include slot('footer') {
}
