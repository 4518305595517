//Rubik font
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');


//CSS variables
:root {
    /**** BORDERS: ****/
    --fw-border-radius-xs: 4px;
    --fw-border-radius-sm: 6px;
    --fw-border-radius-md: 8px;
    --fw-border-radius-lg: 10px;

    /**** COLORS: ****/
    --fw-color-primary-10: #fafbff;
    --fw-color-primary-25: #f5f7ff;
    --fw-color-primary-50: #edf0ff;
    --fw-color-primary-60: #e6eaff;
    --fw-color-primary-70: #dee3ff;
    --fw-color-primary-80: #d6ddff;
    --fw-color-primary-90: #cfd7ff;
    --fw-color-primary-100: #c7d0ff;
    --fw-color-primary-150: #b8c3ff;
    --fw-color-primary-200: #abb9ff;
    --fw-color-primary-300: #8599ff;
    --fw-color-primary-400: #6b84ff;
    --fw-color-primary-500: #4766ff;
    --fw-color-primary-600: #3d57d9;
    --fw-color-primary-700: #3348b5;
    --fw-color-primary-800: #27388c;
    --fw-color-primary-900: #1e2b6b;
    --fw-color-secondary-10: #fcfaff;
    --fw-color-secondary-25: #f8f5ff;
    --fw-color-secondary-50: #f2edfc;
    --fw-color-secondary-60: #ede6fc;
    --fw-color-secondary-70: #e8defc;
    --fw-color-secondary-80: #e3d7fa;
    --fw-color-secondary-90: #dfd2fa;
    --fw-color-secondary-100: #dacbf7;
    --fw-color-secondary-150: #d1bff5;
    --fw-color-secondary-200: #c9b3f5;
    --fw-color-secondary-300: #ae8df0;
    --fw-color-secondary-400: #a079ed;
    --fw-color-secondary-500: #8756e8;
    --fw-color-secondary-600: #7b4ed4;
    --fw-color-secondary-700: #603da6;
    --fw-color-secondary-800: #4a2f80;
    --fw-color-secondary-900: #382461;
    --fw-color-warning-10: #fffdfa;
    --fw-color-warning-25: #fffbf5;
    --fw-color-warning-50: #fff5e8;
    --fw-color-warning-60: #fcf3e6;
    --fw-color-warning-70: #fcf0de;
    --fw-color-warning-80: #faebd7;
    --fw-color-warning-90: #fae9d2;
    --fw-color-warning-100: #ffe3bd;
    --fw-color-warning-150: #f5debf;
    --fw-color-warning-200: #ffd69c;
    --fw-color-warning-300: #ffc26e;
    --fw-color-warning-400: #ffb752;
    --fw-color-warning-500: #ffa424;
    --fw-color-warning-600: #e39220;
    --fw-color-warning-700: #b37319;
    --fw-color-warning-800: #8c5a14;
    --fw-color-warning-900: #6b450f;
    --fw-color-success-10: #fafffe;
    --fw-color-success-25: #f5fffd;
    --fw-color-success-50: #e9f7f4;
    --fw-color-success-60: #e6fcf7;
    --fw-color-success-70: #defcf5;
    --fw-color-success-80: #d7faf2;
    --fw-color-success-90: #d2faf1;
    --fw-color-success-100: #b8e6db;
    --fw-color-success-150: #bff5e8;
    --fw-color-success-200: #93d9c9;
    --fw-color-success-300: #63c7b0;
    --fw-color-success-400: #46bda1;
    --fw-color-success-500: #16ab88;
    --fw-color-success-600: #159e7e;
    --fw-color-success-700: #107a62;
    --fw-color-success-800: #0c5e4b;
    --fw-color-success-900: #094739;
    --fw-color-error-10: #fffafa;
    --fw-color-error-25: #fff5f5;
    --fw-color-error-50: #ffebeb;
    --fw-color-error-60: #fce6e6;
    --fw-color-error-70: #fcdede;
    --fw-color-error-80: #fad7d7;
    --fw-color-error-90: #fad2d2;
    --fw-color-error-100: #ffc4c4;
    --fw-color-error-150: #f5bfbf;
    --fw-color-error-200: #ffa6a6;
    --fw-color-error-300: #ff7d7d;
    --fw-color-error-400: #fa6161;
    --fw-color-error-500: #ff3838;
    --fw-color-error-600: #e03131;
    --fw-color-error-700: #b32727;
    --fw-color-error-800: #8a1e1e;
    --fw-color-error-900: #691717;
    --fw-color-neutral-10: #fafafa;
    --fw-color-neutral-25: #f7f7f7;
    --fw-color-neutral-50: #f4f4f4;
    --fw-color-neutral-60: #f0f0f0;
    --fw-color-neutral-70: #ebebed;
    --fw-color-neutral-80: #e6e6e8;
    --fw-color-neutral-90: #e1e1e3;
    --fw-color-neutral-100: #d7d8db;
    --fw-color-neutral-150: #c8c9cc;
    --fw-color-neutral-200: #b5b8bd;
    --fw-color-neutral-300: #999ba1;
    --fw-color-neutral-400: #7a7e87;
    --fw-color-neutral-500: #5b606b;
    --fw-color-neutral-600: #434954;
    --fw-color-neutral-700: #2c313b;
    --fw-color-neutral-800: #20252e;
    --fw-color-neutral-900: #111317;
    --fw-color-dark-10: #f9f9f9;
    --fw-color-dark-25: #f7f7f7;
    --fw-color-dark-50: #f4f4f4;
    --fw-color-dark-100: #d7d8db;
    --fw-color-dark-200: #b5b8bd;
    --fw-color-dark-300: #999ba1;
    --fw-color-dark-400: #7a7e87;
    --fw-color-dark-500: #5b606b;
    --fw-color-dark-600: #434954;
    --fw-color-dark-700: #2c313b;
    --fw-color-dark-800: #20252e;
    --fw-color-dark-900: #111317;

    --fw-color-white: #ffffff;
    --fw-color-black: var(--fw-color-dark-900);
}


//CSS variables
:root {

  /* Primary */
  --color-primary-main: #5D78FF;
  --color-primary-strong: #415DE8;
  --color-primary-dark: #606A8C;
  --color-primary-light: #ACB3EE;
  --color-primary-super-light: rgba(172, 179, 238, 0.25);

  /* Secondary */
  --color-secondary-main: var(--fw-color-success-400);
  --color-secondary-strong: var(--fw-color-success-500);

  /* Tertiary */
  --color-tertiary-main: #8B58DD;
  --color-tertiary-strong: #9D6CFE;

  /* Dark */
  --color-dark-0: #373C4A;
  --color-dark-1: #2A2E39;
  --color-dark-2: #595D6E;
  --color-dark-navigation-bg: var(--fw-color-neutral-800);
  --color-dark-navigation-light: var(--fw-color-neutral-700);

  /* Grey */
  --color-grey-0: #8B90B0;
  --color-grey-1: #A7ACC8;
  --color-grey-2: #D4D4E0;
  --color-grey-3: #E1E1EE;
  --color-grey-4: #FAFAFF;

  /* White */
  --color-white-f2: #F2F2F2;
  --color-white-fff: #FFFFFF;

  /* Signal positive */
  --color-signal-positive: var(--color-secondary-strong);
  --color-signal-positive-faded: rgba(22, 172, 136, 0.25);
  --color-signal-positive-light: rgba(22, 172, 136, 0.1);

  /* Signal error */
  --color-signal-error: #FF0F6E;
  --color-signal-error-faded: rgba(255, 15, 110, 0.25);
  --color-signal-error-light: rgba(255, 15, 110, 0.1);

  /* Signal warning */
  --color-signal-warning: #FFBC0F;
  --color-signal-warning-faded: rgba(255, 188, 15, 0.25);
  --color-signal-warning-light: #AC7812;

  /**** TYPOGRAPHY: ****/

  /* Font */
  --font-primary-family: 'Rubik', sans-serif;
  --font-primary-weight-normal: 300;
  --font-primary-weight-semi-bold: 500;
  --font-primary-weight-bold: 700;

  //Regular 14/20
  --text-regular-size: 14px;
  --text-regular-line-height: 20px;

  //Regular strong 14/22
  --text-regular-strong-font-weight: var(--font-primary-weight-semi-bold);

  //Body strong 16/24
  --text-body-strong-size: 16px;
  --text-body-strong-line-height: 24px;

  //Small 12/17
  --text-small-size: 12px;
  --text-small-line-height: 17px;

  //H2: 28/39
  --text-h2-size: 28px;
  --text-h2-line-height: 39px;

  //H3: 28/39
  --text-h3-size: 24px;
  --text-h3-line-height: 34px;

  /* Borders */
  --border-radius-standard: 4px;
  --border-radius-big: 8px;

  /* Margins */
  --spacing-sm: 6.5px;
}
