@use "sass:map";
@import "init";

//
// Bootstrap Global functions, variables and mixins
//
$enable-rounded: true;
$enable-prefers-reduced-motion-media-query: false;


// Fonts
$font-family-base:									Rubik, Roboto, Helvetica, sans-serif;

// Color System
$primary:       									fw-color(primary) !default;
$secondary:     									fw-color(secondary) !default;
$success:       									fw-color(success) !default;
$info:          									fw-color(primary, light) !default;
$warning:       									fw-color(warning) !default;
$danger:        									fw-color(error) !default;
$light:         									fw-color(white) !default;
$dark:          									fw-color(dark) !default;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);


// Grid breakpoints
$grid-breakpoints: 									$fw-media-breakpoints !default;

// Border Radiues
$border-radius:               						.25rem !default;
$border-radius-lg:            						.3rem !default;
$border-radius-sm:            						.2rem !default;


// Form & Button Controls
$btn-font-weight:             						normal !default;
$input-plaintext-color:                 			fw-base-color(label, 3) !default;
$input-placeholder-color:               			fw-base-color(label, 2) !default;

$btn-secondary-color:								fw-base-color(label, 3) !default;
$btn-secondary-hover-bg-color:						#f4f5f8 !default;
$input-border-radius:                   			$fw-border-radius !default;
$input-btn-padding-y:       						.65rem;
$input-btn-padding-x:       						1rem;
$input-btn-line-height:     						1.5;

$input-btn-padding-y-sm:    						.5rem;
$input-btn-padding-x-sm:    						1rem;
$input-btn-line-height-sm:  						1.5;

$input-btn-padding-y-lg:    						1.15rem;
$input-btn-padding-x-lg:    						1.65rem;
$input-btn-line-height-lg:  						1.5;

$input-border-color:								fw-base-color(grey, 3) !default;
$input-focus-border-color:							lighten(fw-color(primary), 12%);
$input-disabled-bg:                     			fw-base-color(grey, 1) !default;
$input-readonly-bg:                     			#ffffff !default;
$input-group-icon-color:                     		fw-base-color(label, 2) !default;

$input-group-addon-color:               			fw-base-color(label, 2) !default;
$input-group-addon-bg:                  			fw-base-color(grey, 1) !default;

// Button elevate shadows
$btn-elevate-shadow-1:								0px 0px 11px 0px rgba(56,36,99,0.08);
$btn-elevate-shadow-1-hover:						0px 0px 22px 0px rgba(56,36,99,0.11);
$btn-elevate-shadow-2:								0px 0px 11px 0px rgba(56,36,99,0.10);
$btn-elevate-shadow-2-hover:						0px 0px 22px 0px rgba(56,36,99,0.13);
$btn-elevate-shadow-3:								0px 0px 11px 0px rgba(56,36,99,0.12);
$btn-elevate-shadow-3-hover:						0px 0px 22px 0px rgba(56,36,99,0.15);


// Table
$table-bg:                      					transparent !default;
$table-accent-bg:               					lighten(#f4f5f8, 1%);
$table-hover-bg:                					lighten(#f4f5f8, 2%);
$table-active-bg:               					fw-color(primary, strong);

$table-border-color:            					var(--white-f2);
$table-head-bg:                 					var(--grey-4);
$table-head-color:              					var(--dark-2);
//$table-cell-padding: 100px;

// Card
$card-border-color:                 				fw-base-color(grey, 2) !default;
$card-cap-bg:                       				fw-base-color(grey, 1) !default;


// List group
$list-group-border-color:           				fw-base-color(grey, 2) !default;
$list-group-hover-bg:               				fw-base-color(grey, 2) !default;


// Modal
$modal-inner-padding:								1.25rem !default;
$modal-header-padding:								1.25rem !default;

$modal-content-border-color:        				fw-base-color(grey, 2) !default;
$modal-content-box-shadow-xs:       				0 .25rem .5rem rgba(fw-base-color(grey, 2), .5) !default;
$modal-content-box-shadow-sm-up:   	 				0 .5rem 1rem rgba(fw-base-color(grey, 2), .5) !default;

$modal-header-border-color:         				fw-base-color(grey, 2) !default;
$modal-footer-border-color:         				fw-base-color(grey, 2) !default;


// Pagination
$pagination-color:                  				fw-color(primary) !default;
$pagination-border-color:           				fw-base-color(grey, 2) !default;

$pagination-hover-bg:              	 				fw-base-color(grey, 2) !default;
$pagination-hover-border-color:     				fw-base-color(grey, 2) !default;

$pagination-disabled-border-color:  				fw-base-color(grey, 2) !default;



// Popovers
$popover-font-size:                 				0.9rem !default;
$popover-max-width:                 				276px !default;
$popover-border-width:              				1px !default;
$popover-border-color:              				#ffffff !default;
$popover-border-radius:             				3px !default;
$popover-box-shadow:                				0px 0px 20px 0px rgba(0,0,0,0.15) !default;

$popover-header-bg:                 				#ffffff !default;
$popover-header-color:              				fw-base-color(label, 3) !default;
$popover-header-padding-y:          				1rem !default;
$popover-header-padding-x:          				1.25rem !default;

$popover-body-color:                				fw-base-color(label, 2) !default;



// Tooltips
$tooltip-font-size:                 				0.9rem !default;
$tooltip-max-width:                 				200px !default;
$tooltip-color:                     				fw-base-color(label, 3) !default;
$tooltip-bg:                        				#ffffff !default;
$tooltip-border-radius:             				3px !default;
$tooltip-opacity:                   				1 !default;
$tooltip-padding-y:                 				.75rem !default;
$tooltip-padding-x:                 				1rem !default;
$tooltip-margin:                    				0 !default;

// Custom Toasts
$toast-zindex:										$fw-dropdown-zindex + 100 !default;
$toast-spacing:										2rem  !default;
$toast-width: 										350px !default;
$toast-box-shadow:									$fw-dropdown-shadow !default;

$toast-header-color: 								fw-base-color(label, 3) !default;
$toast-header-background-color: 					#fff !default;
$toast-header-border-color:							fw-base-color(grey, 2) !default;



// Progress bars
$progress-bg:                       				fw-base-color(grey, 2) !default;



// Dropdown
$dropdown-box-shadow:								$fw-dropdown-shadow !default;
$zindex-dropdown:                   				$fw-dropdown-zindex !default;



// Text muted
$text-muted:                  						fw-base-color(label, 2) !default;



// Badges
$badge-padding-y:                   				.5em !default;
$badge-padding-x:                   				.75em !default;
$badge-font-size:                   				85% !default;


// Grid columns
$grid-gutter-width:           						20px !default;


// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-link-color:               				fw-base-color(label, 2) !default;
$dropdown-link-hover-color:         				fw-base-color(label, 3) !default;
$dropdown-link-hover-bg:            				fw-base-color(grey, 1) !default;

$dropdown-link-active-color:        				fw-color(white) !default;
$dropdown-link-active-bg:           				fw-color(primary) !default;

$dropdown-link-disabled-color:      				fw-base-color(label, 1) !default;

$dropdown-header-color:             				fw-base-color(label, 2) !default;
