@use '~Scss/_bootstrap.scss' as *;
@import 'theme/mixins';
@import 'theme/parts';

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .fw-dropdown-menu#{$infix}-left {
            right: auto;
            left: 0;
        }

        .fw-dropdown-menu#{$infix}-right {
            right: 0;
            left: auto;
        }
    }
}

.fw-menu {
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;

    width: fit-content;
    min-width: 14rem;
    max-width: 100vw;
    margin: var(--menu-spacer, .125rem) 0 0; // override default ul

    &.fw-menu--scrollable {
        > div {
            overflow: auto;
            overscroll-behavior: contain;
            max-height: 80vh;

            @include fw-no-scrollbar();
        }
    }
}


.fw-menu {
    --menu-item-padding-x: 10px;
    --menu-spacer: 2px;
    --menu-border-radius: 8px;

    --menu-color: #222;
    --menu-background-color: transparent;
    --menu-border-color: var(--menu-background-color);
    --menu-shadow-color: transparent;

    --menu-link-color: var(--menu-color);
    --menu-link-background-color: var(--menu-background-color);
    --menu-link-background-opacity: var(--menu-background-opacity);
    --menu-link-border-color: var(--menu-link-background-color);

    --menu-hover-color: var(--menu-link-color);
    --menu-hover-background-color: var(--menu-link-background-color);
    --menu-hover-background-opacity: var(--menu-link-background-opacity);
    --menu-hover-border-color: var(--menu-hover-background-color);

    --menu-active-color: var(--menu-hover-color);
    --menu-active-background-color: var(--menu-hover-background-color);
    --menu-active-background-opacity: var(--menu-hover-background-opacity);
    --menu-active-border-color: var(--menu-active-background-color);

    --menu-active-hover-color: var(--menu-active-color);
    --menu-active-hover-background-color: var(--menu-active-background-color);
    --menu-active-hover-background-opacity: var(--menu-active-background-opacity);
    --menu-active-hover-border-color: var(--menu-active-background-color);

    --menu-disabled-color: #666;
    --menu-disabled-background-color: transparent;
    --menu-disabled-background-opacity: 1;
    --menu-disabled-border-color: var(--menu-disabled-background-color);

    // The dropdown menu

    font-size: 12px;
    line-height: 1.5;
    color: var(--menu-color);

    padding: 12px;
    border: 1px solid var(--menu-border-color);
    @include border-radius(var(--menu-border-radius));

    &:not(.fw-menu--inline) {
        background-color: var(--menu-background-color);
        background-clip: padding-box;
        box-shadow: 0 8px 8px var(--menu-shadow-color);
    }

    :is(.fw-dropdown-toggle, .fw-dropdown-item, .fw-dropdown-item-text) {
        --btn-min-height: 36px;
        min-height: var(--btn-min-height);
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: var(--menu-color, #222);
    }

    :is(.fw-dropdown-item, .fw-dropdown-item-text) {
        display: block;
        padding: 7px 12px;
    }

    .fw-dropdown-wrap {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        &.fw-btn-group {
            position: static;
        }
        > .fw-dropdown-item {
            justify-content: initial;

            > span {
                flex: 1;
            }
        }
    }

    .fw-dropdown-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    :is(.fw-dropdown-toggle, .fw-dropdown-item) {
        @extend %fw-menu-item;

        @include menu-btn(
            $color: var(--menu-link-color),
            $border-color: var(--menu-link-border-color),
            $background-color: var(--menu-link-background-color),
            $background-opacity: var(--menu-link-background-opacity),
        );

        &.show {
            display: block;
        }

        &:not(:is([aria-disabled=true], .disabled, :disabled))
        {
            &:not(:is(:hover, :active, .active, [aria-expanded=true])):focus:before {
                box-shadow: 0 0 3px 1px inset var(--menu-hover-background-color);
                opacity: .7;
            }

            &:hover {
                text-decoration: none;
                @include menu-btn(
                    $color: var(--menu-hover-color),
                    $border-color: var(--menu-hover-border-color),
                    $background-color: var(--menu-hover-background-color),
                    $background-opacity: var(--menu-hover-background-opacity),
                );
            }

            &:is([aria-expanded=true],:active,.active) {
                font-weight: 500;
                text-decoration: none;

                @include menu-btn(
                    $color: var(--menu-active-color),
                    $border-color: var(--menu-active-border-color),
                    $background-color: var(--menu-active-background-color),
                    $background-opacity: var(--menu-active-background-opacity),
                );

                &:hover {
                    @include menu-btn(
                        $color: var(--menu-active-hover-color),
                        $border-color: var(--menu-active-hover-border-color),
                        $background-color: var(--menu-active-hover-background-color),
                        $background-opacity: var(--menu-active-hover-background-opacity),
                    );
                }
            }
        }

        &:is(.disabled,:disabled) {
            @include menu-btn(
                $color: var(--menu-disabled-color),
                $border-color: var(--menu-disabled-border-color),
                $background-color: var(--menu-disabled-background-color),
                $background-opacity: var(--menu-disabled-background-opacity),
            );
        }
    }

    > * > :is(.fw-dropdown-item, .fw-btn-group) {
        margin: 2px 0;
    }

    // Dropdown section headers
    .fw-dropdown-header {
        display: block;
        padding: 7px 10px;
        margin-bottom: 0; // for use with heading elements
        color: var(--menu-color);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        white-space: nowrap;
    }

    .fw-dropdown-divider {
        @include nav-divider(var(--menu-divider-color, #D9D9D9), var(--menu-divider-margin, 10px), true);
        &:first-child,
        &:last-child {
            display: none;
        }
    }
}


.fw-menu--light {
    --menu-divider-color: var(--fw-color-neutral-100);
    --menu-shadow-color: rgba(30, 43, 107, 0.1);

    --menu-color: var(--fw-color-neutral-500);
    --menu-background-color: white;
    --menu-border-color: var(--fw-color-neutral-100);

    --menu-link-color: var(--fw-color-neutral-500);

    --menu-hover-color: var(--fw-color-primary-500);
    --menu-hover-background-color: var(--fw-color-primary-50);

    --menu-disabled-color: var(--fw-color-neutral-300);

    --menu-header-color: var(--fw-color-neutral-600);
}

.fw-menu--dark {
    --menu-divider-color: var(--fw-color-neutral-600);
    --menu-shadow-color: rgba(206, 206, 235, 0.1);

    --menu-color: var(--fw-color-neutral-50);
    --menu-background-color: var(--fw-color-neutral-800);
    --menu-border-color: var(--fw-color-neutral-700);

    --menu-hover-color: var(--fw-color-primary-50);
    --menu-hover-background-color: var(--fw-color-primary-300);
    --menu-hover-background-opacity: .4;

    --menu-active-color: var(--fw-color-primary-900);
    --menu-active-background-color: var(--fw-color-primary-200);
    --menu-active-background-opacity: 1;

    --menu-active-hover-background-color: var(--fw-color-primary-100);

    --menu-disabled-color: var(--fw-color-neutral-500);

    --menu-header-color: var(--fw-color-neutral-300);
}
