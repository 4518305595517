@use "~Scss/core" as *;
@import "./nav";


:root {
    --fs-color: var(--fw-color-white);
    --fs-muted-color: var(--fw-color-neutral-400);
    --fs-muted-bg: var(--fw-color-neutral-500);
    --fs-bg: var(--fw-color-neutral-800);
}

$-scroll-padding: 10px;

.root {
    top: 0;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 590px;
    padding: 24px;
    color: var( --fs-color);
    background-color: var(--fs-bg);
    border-left: 1px solid var(--fs-muted-bg);
    font-family: var(--font-primary-family);

    > * {
        @include fw-scrollbar();
    }
}

.head {
    background-color: var(--fs-bg);
    padding-bottom: 24px;
}

.body {
    overflow-y: auto;
    flex: 1;
}

.search {
    display: flex;
    align-items: center;
    gap: 19px;
    color: var(--fs-muted-color);
    background-color: var(--fs-bg);
    border: 1px solid var(--fs-muted-bg);
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 25px;

    input {
        flex: 1;
        border: 0;
        font-size: inherit;
        margin: 0;
        background-color: transparent;
        outline: none;
        color: var(--fs-color);

        &::placeholder {
            color: var(--fs-muted-color);
        }
    }
}




.dropDown {
    width: 450px;
    max-height: 100vh;
    padding: 16px 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--fs-bg);
    border-radius: var(--fw-border-radius-md);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    opacity: 1;
    transition: opacity 0.1s;

    &.animation_close {
        opacity: 0;
    }

    > * {
        @include fw-scrollbar();
    }
}


.sectionHead {
    position: sticky;
    top: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    gap: 8px;
    border-style: solid;
    border-color: var(--fs-muted-bg);
    border-width: 1px 0;
    background: var(--fs-bg);

    padding: 10px 16px 8px;
    margin-bottom: 5px;

    h6 {
        font-size: inherit;
        margin: 0;
    }
}


.sectionBody {
    flex: 1;
    margin: 8px 0;
}


.selected {
    background-color: var(--color-signal-positive-light);
    color: var(--color-secondary-main);
}

.list {
    $-space-y: 16px;
    $-space-x: 24px;
    $-margin-y: -$-space-y;
    $-margin-x: -$-space-x;

    list-style: none;
    padding: 0;
    margin: 0;

    li {
        &:is(:focus, :focus-visible) {
            outline: none;

            & > div {
                background-color: var(--color-dark-navigation-bg);
            }
        }
    }

    li > div {
        display: flex;
        gap: 29px;
        align-items: center;
        border-radius: var(--border-radius-big);
        padding: $-space-y $-space-x;

        &:hover {
            cursor: pointer;
            background-color: var(--fw-color-neutral-700);
            .dropDown {
                display: block;
            }
        }

        .actionIcon {
            margin: $-margin-y calc($-margin-x / 2) $-margin-y $-margin-x;
            padding: $-space-y calc($-space-x / 2) $-space-y $-space-x;
            color: var(--color-primary-dark);

            .favoriteIcon {
                color: var(--color-primary-main);
            }

            .favoriteIconHover {
                display: none;
            }

            &:hover {
                .favoriteIcon {
                    display: none;
                }
                .favoriteIconHover {
                    display: block;
                    color: var(--color-primary-main);
                }
            }
        }
    }
}




.trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px 32px;
    min-height: 72px;

    font-family: var(--font-primary-family);
    font-size: var(--text-regular-size);
    line-height: var(--text-regular-line-height);
    font-weight: 600;

    color: var(--color-white-fff);
    background-color: var(--fw-color-neutral-700);
    border: solid var(--fw-color-neutral-500);
    border-width: 1px 0;

    cursor: pointer;

    .triggerShortIcon {
        display: none;
    }

    &.itemSelected {
        background-color: var(--color-signal-positive-light);
        color: var(--fw-color-success-500);
        border-color: var(--fw-color-success-700);
        font-weight: 400;
    }

    &Icon, &Close {
        height: 22px;
        width: 22px;
    }

    &Text {
        flex: 1;
    }
}

.triggerShort {
    padding: 16px 28px;

    .triggerClose {
        display: none;
    }

    .triggerText {
        display: none;
    }

    .triggerShortIcon {
        display: block;
    }

    &.itemSelected {
        .triggerIcon {
            display: none;
        }

        .triggerClose {
            display: block;
        }
    }
}
