.modal_default {
  padding: 8px;
  min-width: 300px;
  //transform: translateX(-50%);
  z-index: 1000;
  background: var(--color-dark-0);
  border-radius: var(--border-radius-standard);

  &.anchor_left {
    border-top-left-radius: 0;
  }

  &.anchor_center {
  }

  &.anchor_right {
    border-top-right-radius: 0;
  }
}
