
.logoPositionNonSelected {
  color: #a1a2a8 !important;
}

.demoTextBackground {
  background-color: #fafaff;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}
