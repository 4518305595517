@use '~Scss/_bootstrap.scss' as *;
@use 'mixins' as *;


/** Cell - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


.cell__first {}

.cell__last {}

.cell__noWrap {
    white-space: nowrap !important;
}

.cell__auto {
    width: 100% !important
}
.cell__min {
    width: 0 !important
}

.cell {
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--font-family);
    font-weight: var(--font-weight);
    font-style: normal;
    border: 0 solid transparent;

    text-align: var(--cell-align);
    vertical-align: var(--cell-valign);

    transition-duration: 300ms;
    transition-timing-function: ease-out;
    transition-property: background-color, border, border-radius;

    &:is([data-cell='head'], [data-cell='data'], [data-cell='actions'], [data-cell='content']) {
        height: var(--row-height);
        color: var(--color);
        background-color: var(--table-cell-background, var(--table-background-color));
        padding: var(--row-padding-y) var(--row-padding-x);
    }

    &[data-cell='empty'] {}

    &[data-cell='spacer'] {}

    &[data-cell='head'] {
        font-weight: 500;
        font-size: 10px;
        line-height: 1.5;
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }

    &[data-cell='data'] {}

    &[data-cell='actions'] {
        width: 0;
        padding: 0;
        white-space: nowrap;
        --cell-actions-padding-x: calc(var(--row-padding-x) / 2);

        &:first-child {
            padding-left: var(--cell-actions-padding-x);
        }

        &:last-child {
            padding-right: var(--cell-actions-padding-x);
        }

        label {
            padding: var(--cell-actions-padding-x);
        }

        :global(.fw-btn) {
            padding: var(--cell-actions-padding-x);
            //min-height: auto;
        }

        > div {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            //width: fit-content;

        }
    }

    &.cell__first.cell__last {
        width: 100%;
    }


}



/** sticky - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

[data-sticky] {
    &[data-sticky] {
        position: sticky;
        z-index: var(--table-sticky-zindex);
    }

    &[data-sticky='right'] { right: 0 }
    &[data-sticky='left'] { left: 0 }
    &[data-sticky='top'] { top: 0 }
    &[data-sticky='bottom'] { bottom: 0 }
}


/** Row - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.row {

    &[aria-busy=true] {
        pointer-events: none !important;
    }

    &[data-status] {
        position: relative;

        > .cell {
            --table-status-offset: var(--table-row-border-size, 0);
            --table-status-size: 5px;
            --table-status-radius: max(0px, calc(var(--table-row-radius, 0) - 1px));
            --table-cell-background: var(--table-status-color-bg, transparent);
        }
        > .cell__first {
            //&:not([data-sticky]) {
            //    position: relative;
            //}
            &:before {
                content: '';
                position: absolute;
                display: block;
                transition-duration: 300ms;
                transition-timing-function: ease-out;
                transition-property: width, border, border-radius;
                left: var(--table-status-offset);
                top: var(--table-status-offset);
                bottom: var(--table-status-offset);
                width: var(--table-status-radius);
                border-left: var(--table-status-size) var(--table-status-color, transparent) solid;
                border-top-left-radius: var(--table-status-radius);
                border-bottom-left-radius: var(--table-status-radius);
            }
        }
    }

    &[data-status='primary'] {
        --table-status-color: var(--table-primary-color, var(--fw-color-primary-300));
        --table-status-color-bg: var(--table-primary-color-bg, var(--fw-color-primary-25));
    }

    &[data-status='warning'] {
        --table-status-color: var(--table-dirty-color, var(--fw-color-warning-300));
        --table-status-color-bg: var(--table-dirty-color-bg, var(--fw-color-warning-25));
    }

    &[data-status='error'] {
        --table-status-color: var(--table-error-color, var(--fw-color-error-400));
        --table-status-color-bg: var(--table-error-color-bg, var(--fw-color-error-25));
    }

    &[data-status='error2'] {
        --table-status-color: var(--fw-color-neutral-200, var(--fw-color-error-25));
        --table-status-color-bg: var(--table-error-color, var(--fw-color-error-200));
    }

    &[data-status='error2'][aria-selected] {
        > .cell {
            --table-cell-background: var(--table-error-color, var(--fw-color-error-200));
        }
    }

    &[data-status='success'] {
        --table-status-color: var(--table-success-color, var(--fw-color-success-400));
        --table-status-color-bg: var(--table-success-color-bg, var(--fw-color-success-25));
    }

    &[aria-selected],
    &-selected {
        > .cell {
            --table-cell-background: var(--table-selected-color);
        }
    }
}

.row__first {}

.row__last {}

.rowSpacer {
    > .cell {
        padding: 0 var(--table-row-radius, 0);
        width: 100%;
    }
}

.rowDropzone.rowDropzone {
    > .cell {
        padding: 0;
        height: calc(var(--row-height) - 2px);
        > div {
            position: relative;
            padding: var(--row-padding-y) var(--row-padding-x);
            height: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            text-align: center;
            background-color: var(--fw-color-primary-50);
            color: var(--fw-color-primary-500);
            cursor: pointer;
            border-top: 2px dashed var(--fw-color-primary-100);
            border-bottom: 2px dashed var(--fw-color-primary-100);

            transition: background-color 300ms ease-in-out;

            &:hover {
                background-color: hsl(from var(--fw-color-primary-50) h calc(s - 5) calc(l + 1));

            }
            > div {
                text-align: center;
                justify-content: center;
                align-items: center;
            }
        }
    }
}


.row__expandable {}

.row__expanded {}


/** Group - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.group {}

.group-fill {
    height: 100%;
    > tr {
        > td {
            height: 100%;
        }
    }
}
.group-head {}

.group-foot {}

.group-body {
    --table-sticky-zindex: 10;
}


/** Table - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.table {
    --font-size: 14px;
    --line-height: 1.5;
    --font-family: var(--font-primary-family);
    --font-weight: var(--font-primary-weight-normal);
    --color: #101217;
    --background-color: white;
    --border-radius: 8px;

    --table-background-color: white;
    --table-border-size: 1px;
    --table-border-color: var(--fw-color-neutral-100);
    --table-sticky-zindex: 15;
    --table-spacing: 5px;

    --table-hover-color: var(--fw-color-neutral-50);
    --table-selected-color: var(--fw-color-primary-25);

    --table-primary-color: var(--fw-color-primary-300);
    --table-primary-color-bg: var(--fw-color-primary-25);
    --table-dirty-color: var(--fw-color-warning-300);
    --table-dirty-color-bg: var(--fw-color-warning-25);
    --table-error-color: var(--fw-color-error-400);
    --table-error-color-bg: var(--fw-color-error-25);
    --table-success-color: var(--fw-color-success-400);
    --table-success-color-bg: var(--fw-color-success-25);


    --table-column-gap: 5px;

    --row-height: 47px;
    --row-padding-y: 8px;
    --row-padding-x: 16px;
    --row-gap: 16px;

    --row-border-size: 1px;
    --row-border2: 1px red;
    --row-border-color: var(--table-border-color);


    --head-height: 56px;
    --head-padding-y: var(--row-padding-y);
    --head-padding-x: var(--row-padding-x);

    --head-color: var(--fw-color-neutral-700);
    --head-background-color: var(--fw-color-neutral-25);

    --head-border: var(--row-border-size);
    --head-border-color: var(--row-border-color);


    --cell-align: left;
    --cell-valign: middle;
}

.table {
    position: static;
    transition: opacity ease-in-out 300ms;
    flex: 1 100%;
    overflow: hidden;

    &[aria-busy=true] {
        position: relative !important;
        pointer-events: none !important;
    }

    > .main {
        > table {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            border-collapse: separate;
            border-spacing: 0;

            > :is(.group-head,.group-foot) > .row > .cell {
                color: var(--head-color);
                --row-height: var(--head-height);
                --row-padding-y: var(--head-padding-y);
                --row-padding-x: var(--head-padding-x);
                --table-background-color: var(--head-background-color);
                --table-status-color: var(--head-background-color);
            }
        }
    }

    &.fit {
        height: 100%;

        > .main {
            height: 100%;
        }
    }

    &.table--scroll {
        display: flex;
        flex-direction: column;
        > .main {
            overflow: auto;
        }
        &.fit {
            max-height: 100%;
            > .main {
                max-height: 100%;
            }
        }

        &.activeDropdown {
            overflow: hidden;
        }
    }
}

.table.tableDropzone {

    .limits {
        position: absolute;
        right: calc(var(--row-padding-x) * 2);
    }

    .tableDropzoneContent {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff66;
        z-index: 100;
    }

    .tableDropzonePlaceholder {
        width: 100%;
        padding: 5%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--fw-color-neutral-200);
        font-size: 20px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
        font-weight: 400;
        user-select: none;
        position: relative;
        gap: 10px;

        a {
            color: var(--fw-color-neutral-200);
            text-decoration: underline;
            font-weight: 500;
            cursor: pointer;

            &:hover,
            &:focus {
                color: var(--fw-color-neutral-200);
            }
        }
    }

    &.tableDropzoneActive {
        position: relative;
        border-color: var(--fw-color-primary-500);

        .tableDropzoneContent {
            display: block;
        }

        table {
            opacity: .6;
        }

        &.tableDropzoneReject {
            border-color: var(--table-error-color);
        }

        &.tableDropzoneAccept {
            border-color: var(--table-success-color);
        }
    }
}

.table--fixed {
    > .main > table {
        table-layout: fixed;
    }
}

.table--divider {
    --table-divider-color: var(--divider-color, var(--table-border-color));
    --table-divider-size: var(--table-border-size);

    > .main > table > .group-body > .rowSpacer:not(:is(:first-child, :last-child)) > .cell:after {
        content: '';
        display: block;
        height: var(--table-divider-size);
        width: 100%;
        background-color: var(--table-divider-color);
    }
}

.table--spacing {
    --table-spacing: var(--table-gap, 8px);

    > .main > table > .group-body > .rowSpacer {
        &:not(:first-child) > .cell {
            padding-top: var(--table-spacing);
        }
        &:not(:last-child) > .cell {
            padding-bottom: var(--table-spacing);
        }
    }
}

.table--border {
    border: solid var(--table-border-color) var(--table-border-size);
}

.table--radius {
    --table-radius: var(--border-radius, 8px);

    border-radius: var(--table-radius);
}

.table--rowRadius {
    --table-row-radius: var(--table-radius, var(--border-radius, 8px));

    > .main > table > .group-body > .row {
        &:not(.row__expanded) {
            & > .cell__first {
                border-top-left-radius: var(--table-row-radius);
                border-bottom-left-radius: var(--table-row-radius);
            }
            & > .cell__last {
                border-top-right-radius: var(--table-row-radius);
                border-bottom-right-radius: var(--table-row-radius);
            }
        }

        &.row__expanded {
            &.row__first {
                & > .cell__first { border-top-left-radius: var(--table-row-radius) }
                & > .cell__last { border-top-right-radius: var(--table-row-radius) }
            }
            &.row__last {
                & > .cell__first { border-bottom-left-radius: var(--table-row-radius) }
                & > .cell__last { border-bottom-right-radius: var(--table-row-radius)}
            }
            &[data-status] > .cell__first:before {
                border-bottom-left-radius: 0;
            }
        }
    }
}

.table--hover {
    > .main > table > .group-body > .row:not(.row__expanded):hover > .cell:not([data-cell='content']) {
        --table-cell-background: var(--table-hover-color, #F4F4F4);
    }
}

.activeDropdown {}
