// stylelint-disable selector-no-qualifying-type
@use "~Scss/core" as *;

//
// Base styles
//

:root {
    --fw-card-height: auto;
    --fw-card-spacer-x: 24px;
    --fw-card-spacer-y: 24px;
    --fw-card-bg: white;
    --fw-card-border-color: var(--fw-color-neutral-100);
    --fw-card-border-radius: 8px;
    --fw-card-border-width: 1px;
    --fw-card-inner-border-radius: 6px;
}

.fw-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 160px; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    word-wrap: break-word;
    height: var(--fw-card-height);
    background-color: var(--fw-card-bg);
    background-clip: border-box;
    border: 1px solid var(--fw-card-border-color);
    border-radius: var(--fw-card-border-radius);
    justify-content: space-between;

    > hr {
        margin-right: 0;
        margin-left: 0;
    }

    &.fw-card--fit {
        flex-grow: 1;
        .fw-card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.fw-card-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    // Workaround for the image size bug in IE
    // See: https://github.com/twbs/bootstrap/pull/28855
    min-height: 1px;
    padding: var(--fw-card-spacer-x);
}
