@use "~Scss/bootstrap" as *;
@use "sass:math";


/// Grid system

@mixin fw-make-container($gutter: $grid-gutter-width) {
    width: 100%;
    padding-right: var(--fw-row-gap, 0);
    padding-left: var(--fw-row-gap, 0);
    margin-right: auto;
    margin-left: auto;
}


/// Rows

@mixin fw-make-row($gutter: $grid-gutter-width) {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--fw-row-gap, 0) * -1);
    margin-left: calc(var(--fw-row-gap, 0) * -1);
}


/// Row columns

@mixin fw-row-cols($count) {
    > * {
        flex: 0 0 math.div(100%, $count);
        max-width: math.div(100%, $count);
    }
}


/// Columns

@mixin fw-make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage(math.div($size, $columns));
    max-width: percentage(math.div($size, $columns));
}

@mixin fw-make-col-auto() {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; // Reset earlier grid tiers
}

@mixin fw-make-col-offset($size, $columns: $grid-columns) {
    $num: math.div($size, $columns);
    margin-left: if($num == 0, 0, percentage($num));
}


// Framework grid generation

@mixin fw-make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    // Common properties for all breakpoints
    %grid-column {
        position: relative;
        width: 100%;
        padding-right: var(--fw-row-gap, 0);
        padding-left: var(--fw-row-gap, 0);
    }

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @if $columns > 0 {
            // Allow columns to stretch full width below their breakpoints
            @for $i from 1 through $columns {
                .fw-col#{$infix}-#{$i} {
                    @extend %grid-column;
                }
            }
        }

        .fw-col#{$infix},
        .fw-col#{$infix}-auto {
            @extend %grid-column;
        }

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            // Provide basic `.fw-col-{bp}` classes for equal-width flexbox columns
            .fw-col#{$infix} {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            @if $grid-row-columns > 0 {
                @for $i from 1 through $grid-row-columns {
                    .fw-row-cols#{$infix}-#{$i} {
                        @include fw-row-cols($i);
                    }
                }
            }

            .fw-col#{$infix}-auto {
                @include fw-make-col-auto();
            }

            @if $columns > 0 {
                @for $i from 1 through $columns {
                    .fw-col#{$infix}-#{$i} {
                        @include fw-make-col($i, $columns);
                    }
                }
            }

            .order#{$infix}-first { order: -1; }

            .order#{$infix}-last { order: $columns + 1; }

            @for $i from 0 through $columns {
                .order#{$infix}-#{$i} { order: $i; }
            }

            @if $columns > 0 {
                // `$columns - 1` because offsetting by the width of an entire row isn't possible
                @for $i from 0 through ($columns - 1) {
                    @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
                        .offset#{$infix}-#{$i} {
                            @include fw-make-col-offset($i, $columns);
                        }
                    }
                }
            }
        }
    }
}
