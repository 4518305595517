@use "sass:map";
@use "~Scss/core" as *;


.fw-dropdown {
    --menu-spacer: 1px;

    &.fw-btn-group {
        display: flex;
    }
}

// The dropdown menu
.fw-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    z-index: $fw-dropdown-zindex;

    width: fit-content;
    min-width: 14rem;
    max-width: 100vw;

    &.show {
        display: block;
    }

    .fw-dropup & {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: var(--menu-spacer, .125rem);
    }

    .fw-dropright & {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: var(--menu-spacer, .125rem);
    }

    .fw-dropleft & {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: var(--menu-spacer, .125rem);
    }

    &:is([x-placement^="top"], [x-placement^="right"], [x-placement^="bottom"], [x-placement^="left"]) {
        right: auto;
        bottom: auto;
    }
}


// Links, buttons, and more within the dropdown menu

.fw-dropdown-item {
    display: block;
    clear: both;
    text-decoration: none;
    text-align: inherit;
    white-space: nowrap;

    width: 100%;
    background-color: transparent;
    border: 0;

    &:is([aria-disabled=true], .disabled, :disabled) {
        pointer-events: none !important;
    }
}

.fw-dropdown-toggle {
    white-space: nowrap;

    &.fw-caret:after {
        @include caret();

        .fw-dropup > & {
            @include caret(up);
        }

        .fw-dropright > & {
            @include caret(right);

            &::after {
                vertical-align: 0;
            }
        }

        .fw-dropleft > & {
            @include caret(left);

            &::after {
                vertical-align: 0;
            }
        }
    }
}
