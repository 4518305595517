@import "css-vars";

//Global styles for new frontend
.fw-v2,
.fw-react-root {
    font-family: var(--font-primary-family);
    font-weight: var(--font-primary-weight-normal);
    font-size: var(--text-regular-size);
    line-height: var(--text-regular-line-height);
    height: 100%;
    width: 100%;
}

hr {
    width: 100%;
}

body {
    --ck-z-modal: 12312323;
}

input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    margin: 0;
    width: 100%;
    cursor: pointer;

    $-transition-duration: 250ms;
    $-track-offset: 9px;
    $-track-height: 2px;
    $-height: ($-track-offset * 2) + $-track-height;
    $-thumb-height: $-height + 1px;

    --fw-range-track-color: var(--fw-color-neutral-400);
    --fw-range-thumb-color: var(--fw-color-primary-400);

    &:focus {
        outline: none;
    }

    &:hover {
        --fw-range-track-color: var(--fw-color-primary-400);
        --fw-range-thumb-color: var(--fw-color-primary-500);
    }

    // Track

    @mixin slider-track {
        width: 100%;
        margin: $-track-offset 0;
        height: $-track-height;
        cursor: pointer;
        background: var(--fw-range-track-color);
        border: none;
        border-radius: 1px;
        transition-duration: $-transition-duration;
        transition-property: outline-width, box-shadow, background-color;
        transition-timing-function: ease-in-out;
    }

    &::-webkit-slider-runnable-track {
        @include slider-track;
    }

    &::-moz-range-track {
        @include slider-track;
    }

    &::-ms-track {
        width: 100%;
        margin: $-track-offset 0;
        height: $-track-height;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    // Slider thumb

    @mixin slider-thumb {
        height: $-thumb-height;
        width: $-thumb-height;
        border-radius: 50%;
        cursor: pointer;
        transition-duration: $-transition-duration;
        transition-property: outline-width, box-shadow, background-color;
        transition-timing-function: ease-in-out;
        background-color: var(--fw-range-thumb-color);
        outline: 2px solid #ffffff99;
        box-shadow: 0 0 0 0 #4766FF33;
        &:hover {
            background-color: var(--fw-range-thumb-color);
            box-shadow: 0 0 2px 12px #4766FF33;
        }
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin-top: -10px;
        @include slider-thumb;
    }
    &::-moz-range-thumb {
        @include slider-thumb;
    }
    &::-ms-thumb {
        margin-top: 1px;
        @include slider-thumb;
    }

}

.fws-col {
    display: flex;
    flex-direction: column;
}

.fws-fill {
    @extend .fws-col;
    flex: 1 100%;
    min-height: 0;
}

.fws-auto {
    @extend .fws-col;
    flex: 1 0 auto;
    min-height: 0;
}

#fw_app .fw-app.fw-app.fw-app {
    .fw-root {
        @extend .fws-col;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    .fw-page {
        display: flex;
        flex: 1 1 100%;
        height: 100%
    }

    .fw-side {
        @extend .fws-col;
        flex: 0 0 auto;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1000;
        height: 100vh;
    }


    &.fw-content-locked .fw-content {
        position: relative;
        overflow: hidden;
        z-index: 0;
    }

    & {
        .fw-main {
            @extend .fws-col;
            flex: 1 100%;
            min-height: 0;
            height: auto;
            overflow: auto
        }

        .fw-content {
            @extend .fws-col;
            height: inherit;
            flex: 1 1 auto;
            min-height: 0;
        }

        .fw-content-body {
            height: inherit;
            min-height: 0;
        }
    }

    &.fw-app--new-content {
        .fw-main {
            height: 100%;
        }
    }
    &.fw-app--new {
        background-color: white;

        .fw-pageoverview {
            padding: 0!important;
            > *:empty {
                display: none;
            }
        }

        .fw-react-root {
            height: inherit;
            min-height: 0;
        }
    }

}
