@use "~Scss/bootstrap" as *;
@use "mixins" as *;



:root {
    --fw-row-gap: #{$grid-gutter-width * .5};
    --fw-grid\\gutter-width: #{$grid-gutter-width * .5};
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
// Single container class with breakpoint max-widths
.fw-container,
.fw-container-fluid {
    @include fw-make-container();
}

// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $container-max-widths {
    .fw-container-#{$breakpoint} {
        @extend .fw-container-fluid;
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        %responsive-container-#{$breakpoint} {
            max-width: $container-max-width;
        }

        // Extend each breakpoint which is smaller or equal to the current breakpoint
        $extend-breakpoint: true;

        @each $name, $width in $grid-breakpoints {
            @if ($extend-breakpoint) {
                .fw-container#{breakpoint-infix($name, $grid-breakpoints)} {
                    @extend %responsive-container-#{$breakpoint};
                }

                // Once the current breakpoint is reached, stop extending
                @if ($breakpoint == $name) {
                    $extend-breakpoint: false;
                }
            }
        }
    }
}

// Row
//
// Rows contain your columns.

.fw-row {
    @include fw-make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.fw-no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .fw-col,
    > [class*="fw-col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

// Columns
//
// Common styles for small and large grid columns

@include fw-make-grid-columns();
