// stylelint-disable selector-no-qualifying-type
@use "~Scss/bootstrap" as *;

//
// Base styles
//

// Make the div behave like a button
.fw-btn-group,
.fw-btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; // match .fw-btn alignment given font-size hack above
    align-items: center;

    > .fw-btn {
        position: relative;
        flex: 0 1 auto;

        // Bring the hover, focused, and "active" buttons to the front to overlay
        // the borders properly
        @include hover() {
            z-index: 1;
        }
        // TODO(AJ): optimize selectors
        &:focus,
        &:active,
        &.active {
            z-index: 1;
        }
    }
}

// Optional: Group multiple button groups together for a toolbar
.fw-btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input-group {
        width: auto;
    }
}

.fw-btn-group {
    flex: 0 1 auto;
    min-width: 0;
    // Prevent double borders when buttons are next to each other
    // TODO(AJ): optimize selectors
    > .fw-btn:not(:first-child),
    > .fw-btn-group:not(:first-child) {
        margin-left: -$btn-border-width;
    }

    // Reset rounded corners
    &.fw-btn-group-stacked {
        // TODO(AJ): optimize selectors
        > .fw-dropdown-item:not(:last-child),
        > .fw-btn:not(:last-child):not(.fw-dropdown-toggle),
        > .fw-btn-group:not(:last-child) > :is(.fw-btn, .fw-dropdown-item) {
            &,
            &:before {
                @include border-right-radius(0);
            }
        }
        // TODO(AJ): optimize selectors
        > .fw-dropdown-item:not(:first-child),
        > .fw-btn:not(:first-child),
        > .fw-btn-group:not(:first-child) > :is(.fw-btn, .fw-dropdown-item) {
            &,
            &:before {
                @include border-left-radius(0);
            }
        }
    }
}

.fw-btn-group-condense {
    > .fw-btn {
        padding-right: calc(var(--btn-padding-x) / 2);
        padding-left: calc(var(--btn-padding-x) / 2);
    }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

//.fw-btn-group-sm > .fw-btn { @extend .fw-btn-sm; }
//.fw-btn-group-lg > .fw-btn { @extend .fw-btn-lg; }


//
// Split button dropdowns
//

.fw-dropdown-toggle-split.fw-dropdown-toggle-split {
    padding-right: $btn-padding-x * .75;
    padding-left: $btn-padding-x * .75;
    // TODO(AJ): optimize selectors
    &::after,
    .fw-dropup &::after,
    .fw-dropright &::after {
        margin-left: 0;
    }

    .fw-dropleft &::before {
        margin-right: 0;
    }
}

.fw-btn-sm + .fw-dropdown-toggle-split {
    padding-right: $btn-padding-x-sm * .75;
    padding-left: $btn-padding-x-sm * .75;
}

.fw-btn-lg + .fw-dropdown-toggle-split {
    padding-right: $btn-padding-x-lg * .75;
    padding-left: $btn-padding-x-lg * .75;
}


// The clickable button for toggling the menu
// Set the same inset shadow as the :active state
.fw-btn-group.show .fw-dropdown-toggle {
    @include box-shadow($btn-active-box-shadow);

    // Show no shadow for `.fw-btn-link` since it has no other button styles.
    &.fw-btn-link {
        @include box-shadow(none);
    }
}


//
// Vertical button groups
//

.fw-btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // TODO(AJ): optimize selectors
    > .fw-btn,
    > .fw-btn-group {
        width: 100%;
    }
    // TODO(AJ): optimize selectors
    > .fw-btn:not(:first-child),
    > .fw-btn-group:not(:first-child) {
        margin-top: -$btn-border-width;
    }

    // Reset rounded corners
    &.fw-btn-group-stacked {
        // TODO(AJ): optimize selectors
        > .fw-btn:not(:last-child):not(.fw-dropdown-toggle),
        > .fw-btn-group:not(:last-child) > .fw-btn {
            @include border-bottom-radius(0);
        }
        // TODO(AJ): optimize selectors
        > .fw-btn:not(:first-child),
        > .fw-btn-group:not(:first-child) > .fw-btn {
            @include border-top-radius(0);
        }
    }
}


// Checkbox and radio options
//
// In order to support the browser's form validation feedback, powered by the
// `required` attribute, we have to "hide" the inputs via `clip`. We cannot use
// `display: none;` or `visibility: hidden;` as that also hides the popover.
// Simply visually hiding the inputs via `opacity` would leave them clickable in
// certain cases which is prevented by using `clip` and `pointer-events`.
// This way, we ensure a DOM element is visible to position the popover from.
//
// See https://github.com/twbs/bootstrap/pull/12794 and
// https://github.com/twbs/bootstrap/pull/14559 for more information.

.fw-btn-group-toggle {
    // TODO(AJ): optimize selectors
    > .fw-btn,
    > .fw-btn-group > .fw-btn {
        margin-bottom: 0; // Override default `<label>` value
        // TODO(AJ): optimize selectors
        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }
    }
}
