@use "~Atoms/Dropdown/theme/_mixins.scss" as dropdown;


.react-datepicker {
    &__input-container {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    &-wrapper {
        display: inline-block;
        padding: 0;
        border: 0;
        width: 100%;
    }

    &__aria-live {
        display: none;
    }

    &__close-icon {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        outline: 0;
        padding: 0 6px 0 0;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        &::after {
            cursor: pointer;
            background-color: #415DE8;
            color: #fff;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            padding: 2px;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            content: "×";
        }
    }

    &__today-button {
        background: #f0f0f0;
        border-top: 1px solid #aeaeae;
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        padding: 5px 0;
        clear: left;
    }
}


.react-datepicker-popper {
    z-index: 100100200;

    &[data-placement^=top] {
        padding-bottom: 10px;
    }

    &[data-placement^=bottom] {
        padding-top: 10px;
    }

    &[data-placement^=left] {
        padding-right: 8px;
    }

    &[data-placement^=right] {
        padding-left: 8px;
    }
}


// Header - - - - - - - - - - - - - -

.react-datepicker {
    z-index: 200;
    display: inline-block;
    position: relative;
}

// Container
.react-datepicker__month--container,
.react-datepicker__year--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}

// Header
.react-datepicker__header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    position: relative;
}


// Wrappers - - - - - - - - - - - - - -
%picker-row-wrap {
    display: flex;
    gap: 0;
    flex-wrap: nowrap;
    align-items: stretch;

}
%picker-grid-wrap {
    display: grid;
    white-space: nowrap;
}

// Days wrapper
.react-datepicker__week,
.react-datepicker__day-names {
    @extend %picker-row-wrap;
    > * {
        width: 35px;
        height: 35px;
    }
}

// Month wrapper
.react-datepicker__month-wrapper {
    @extend %picker-row-wrap;
    > * {
        width: 80px;
        height: 50px;
    }
}

// Quarter wrapper
.react-datepicker__quarter-wrapper {
    @extend %picker-grid-wrap;
    grid-template-columns: 80px 80px;
    grid-template-rows: 40px 40px;
}

// Year wrapper
.react-datepicker__year-wrapper {
    @extend %picker-grid-wrap;
    grid-template-columns: repeat(4, 60px);
    grid-auto-rows: 35px;
    grid-row-gap: 8px;
}

// Items
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    margin: 0;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

// Menu
.react-datepicker.fw-menu {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: "Rubik", "Roboto", "Helvetica", "Arial", sans-serif;
    color: var(--picker-color, #222);
    &--light {
        --picker-color: var(--fw-color-neutral-600);
        --picker-header-color: var(--fw-color-neutral-200);
        --picker-disabled-color: var(--fw-color-neutral-300);
        --picker-disabled-background-color: transparent;

        --picker-hover-color: var(--fw-color-primary-500);
        --picker-hover-background-color: var(--fw-color-primary-70);
        --picker-active-color: var(--fw-color-primary-25);
        --picker-active-background-color: var(--fw-color-primary-500);
    }

    &--dark {
        --picker-color: var(--fw-color-neutral-50);
        --picker-header-color: var(--fw-color-neutral-300);
        --picker-disabled-color: var(--fw-color-neutral-500);
        --picker-disabled-background-color: transparent;

        --picker-hover-color: var(--fw-color-primary-50);
        --picker-hover-background-color: var(--fw-color-primary-400);
        --picker-active-color: var(--fw-color-primary-25);
        --picker-active-background-color: var(--fw-color-primary-600);
    }
}

// Day name
.react-datepicker__day-name {
    font-size: 11px;
    line-height: 1.5em;
    letter-spacing: 0.03em;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--picker-header-color, var(--picker-color, var(--fw-color-neutral-500)));
}

// Highlighted
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    &--highlighted {
        border-radius: 0.3rem;
        background-color: #3dcc4a;
        color: #fff;
        &:hover {
            background-color: #32be3f;
        }
        &-custom-1 {
            color: magenta;
        }
        &-custom-2 {
            color: green;
        }
    }
}



// Read view - - - - - - - - - - - - - - - -

%read-view-arrow {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}

.react-datepicker__navigation-icon::before {
    @extend %read-view-arrow;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    @extend %read-view-arrow;
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;

    &:hover {
        cursor: pointer;

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow {
            border-top-color: #b3b3b3;
        }
    }
    &--down-arrow {
        transform: rotate(135deg);
        right: -16px;
        top: 0;
    }
}


// Dropdown - - - - - - - - - - - - - - - -

.react-datepicker__year,
.react-datepicker__month,
.react-datepicker__month-year {
    &-dropdown {
        background-color: #f0f0f0;
        position: absolute;
        width: 50%;
        left: 25%;
        top: 30px;
        z-index: 1;
        text-align: center;
        border-radius: 0.3rem;
        border: 1px solid #aeaeae;

        &-container--scroll {
            display: inline-block;
            margin: 0 2px;
        }

        &:hover {
            cursor: pointer;
        }

        &--scrollable {
            height: 150px;
            overflow-y: scroll;
        }
    }
    &-option {
        line-height: 20px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &:first-of-type {
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
        }
        &:last-of-type {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
        }
        &:hover {
            background-color: #ccc;

            .react-datepicker__navigation--years-upcoming {
                border-bottom-color: #b3b3b3;
            }
            .react-datepicker__navigation--years-previous {
                border-top-color: #b3b3b3;
            }
        }
        &--selected {
            position: absolute;
            left: 15px;
        }
    }
}


// Navigation - - - - - - - - - - - - - -

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
        left: 10px;
    }
    &--next {
        right: 10px;
    }
    &--next--with-time:not(&--next--with-today-button) {
        right: 85px;
    }
    &--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        &-previous {
            top: 4px;
        }
        &-upcoming {
            top: -4px;
        }
    }
    &:hover *::before {
        border-color: #a6a6a6;
    }
    &-icon {
        position: relative;
        top: 10px;
        font-size: 20px;
        width: 0;
        &--next {
            top: 10px;
            &::before {
                transform: rotate(45deg);
                left: -7px;
            }
        }
        &--previous {
            right: -2px;
            &::before {
                transform: rotate(225deg);
                right: -7px;
            }
        }
    }
}


// Portal - - - - - - - - - - - - - -

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;

        @media (max-width: 400px), (max-height: 550px) {
            width: 2rem;
            line-height: 2rem;
        }
    }
}


// Theme - - - - - - - - - - - - - -

$-item-selector: selector-append('.react-datepicker', '__day, __month-text, __quarter-text, __year-text');
$-disabled-selector: selector-append($-item-selector, '--disabled');
$-outside-selector: selector-append($-item-selector, '--outside-month');
$-weekend-selector: selector-append($-item-selector, '--weekend');
$-in-selecting-range-selector: selector-append($-item-selector, '--in-selecting-range');
$-in-selecting-range-start-selector: selector-append($-item-selector, '--selecting-range-start');
$-in-selecting-range-end-selector: selector-append($-item-selector, '--selecting-range-end');
$-in-range-selector: selector-append($-item-selector, '--in-range');
$-in-range-start-selector: selector-append($-item-selector, '--range-start');
$-in-range-end-selector: selector-append($-item-selector, '--range-end');
$-selected-selector: selector-append($-item-selector, '--selected, --range-start, --range-end');
$-selected-selector2: selector-append($-item-selector, '--selected, --range-start, --selecting-range-start, --range-end, --selecting-range-end');

#{$-item-selector} {
    @include dropdown.menu-item {
        --menu-border-radius: 6px;

        cursor: pointer;
        @include dropdown.menu-btn(
            $color: var(--picker-color),
        );

        &:not(:is(#{$-disabled-selector})) {
            &:hover {
                @include dropdown.menu-btn(
                    $color: var(--picker-hover-color),
                    $background-color: var(--picker-hover-background-color),
                );
            }
        }

        &:is(#{$-outside-selector}) {
            @include dropdown.menu-btn(
                $color: var(--picker-disabled-color),
            );
        }

        &:is(#{$-selected-selector2}),
        .react-datepicker__month:hover &:is(#{$-selected-selector2}),
        {
            font-weight: 500;

            @include dropdown.menu-btn(
                $color: var(--picker-active-color),
                $background-color: var(--picker-active-background-color),
            );

            &:hover {
                @include dropdown.menu-btn(
                    $color: var(--picker-active-hover-color, var(--picker-active-color)),
                    $background-color: var(--picker-active-hover-background-color, var(--picker-active-background-color)),
                );
            }
        }

        &:is(#{$-disabled-selector}) {
            pointer-events: none;

            @include dropdown.menu-btn(
                $color: var(--picker-disabled-color),
                $background-color: var(--picker-disabled-background-color),
            );
        }

        &:is(#{$-in-range-selector}),
        .react-datepicker__month:hover &:is(#{$-in-selecting-range-selector})
        {
            &:not(:is(#{$-selected-selector2})) {
                @include dropdown.menu-btn(
                    $background-color: var(--picker-hover-background-color),
                    $background-opacity: .5,
                );

                &:before {
                    top: var(--menu-border-radius);
                    height: calc(100% - 2 * var(--menu-border-radius));
                    border-radius: 0;
                }
            }
        }
    }
}



// Ranges - - - - - - - - - - - - - - - -
// TODO(AJ)
//.react-datepicker__month--selecting-range {
//    .react-datepicker__day--in-range:not(
//        .react-datepicker__day--in-selecting-range,
//        .react-datepicker__month-text--in-selecting-range,
//        .react-datepicker__quarter-text--in-selecting-range,
//        .react-datepicker__year-text--in-selecting-range
//    ),
//    .react-datepicker__month-text--in-range:not(
//        .react-datepicker__day--in-selecting-range,
//        .react-datepicker__month-text--in-selecting-range,
//        .react-datepicker__quarter-text--in-selecting-range,
//        .react-datepicker__year-text--in-selecting-range
//    ),
//    .react-datepicker__quarter-text--in-range:not(
//        .react-datepicker__day--in-selecting-range,
//        .react-datepicker__month-text--in-selecting-range,
//        .react-datepicker__quarter-text--in-selecting-range,
//        .react-datepicker__year-text--in-selecting-range
//    ),
//    .react-datepicker__year-text--in-range:not(
//        .react-datepicker__day--in-selecting-range,
//        .react-datepicker__month-text--in-selecting-range,
//        .react-datepicker__quarter-text--in-selecting-range,
//        .react-datepicker__year-text--in-selecting-range
//    ) {
//        background-color: #f0f0f0;
//        color: #000;
//    }
//}


// Time - - - - - - - - - - - - - -

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.react-datepicker--time-only {
    .react-datepicker__triangle {
        left: 35px;
    }
    .react-datepicker__time-container {
        border-left: 0;
    }
    .react-datepicker__time,
    .react-datepicker__time-box {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;

    .react-datepicker-time__caption {
        display: inline-block;
    }
    .react-datepicker-time__input-container {
        display: inline-block;

        .react-datepicker-time__input {
            display: inline-block;
            margin-left: 10px;
            input {
                width: auto;
            }
            input[type=time]::-webkit-inner-spin-button,
            input[type=time]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type=time] {
                -moz-appearance: textfield;
            }
        }
        .react-datepicker-time__delimiter {
            margin-left: 5px;
            display: inline-block;
        }
    }
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;

    &--with-today-button {
        display: inline;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        position: absolute;
        right: -72px;
        top: 0;
    }
    .react-datepicker__time {
        position: relative;
        background: white;
        border-bottom-right-radius: 0.3rem;
        .react-datepicker__time-box {
            width: 85px;
            overflow-x: hidden;
            margin: 0 auto;
            text-align: center;
            border-bottom-right-radius: 0.3rem;
            ul.react-datepicker__time-list {
                list-style: none;
                margin: 0;
                height: calc(195px + (1.7rem / 2));
                overflow-y: scroll;
                padding-right: 0;
                padding-left: 0;
                width: 100%;
                box-sizing: content-box;
                li.react-datepicker__time-list-item {
                    height: 30px;
                    padding: 5px 10px;
                    white-space: nowrap;
                }
                li.react-datepicker__time-list-item:hover {
                    cursor: pointer;
                    background-color: #f0f0f0;
                }
                li.react-datepicker__time-list-item--selected {
                    background-color: #415DE8;
                    color: white;
                    font-weight: 400;
                }
                li.react-datepicker__time-list-item--selected:hover {
                    background-color: #415DE8;
                }
                li.react-datepicker__time-list-item--disabled {
                    color: #ccc;
                }
                li.react-datepicker__time-list-item--disabled:hover {
                    cursor: default;
                    background-color: transparent;
                }
            }
        }
    }
}
