// stylelint-disable selector-no-qualifying-type
@use "~Scss/bootstrap" as *;

$fw-tooltip-opacity: .95 !default;

@mixin slot($slots...) {
    @include fw-slot('tooltip', $slots...) {
        @content;
    }
}

:root {
    --fw-tooltip\\background-color: var(--fw-color-neutral-700);
    --fw-tooltip\\icon-width: 16px;
    --fw-tooltip\\icon-height: 8px;
    --fw-tooltip\\icon-offset: 7px;
    --fw-tooltip\\icon-space: 2px;
    --fw-tooltip\\padding: 8px 12px;
    --fw-tooltip\\border-radius: 4px;
}

// Base class
@include slot('root') {
    position: absolute;
    z-index: 100100;
    display: block;
    margin: 0;
    padding: 0;

    @include reset-text();
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5em;
    word-wrap: break-word;
    opacity: 0;

    &.show {
        opacity: $fw-tooltip-opacity;
    }

    @include slot('arrow') {
        position: absolute;
        display: block;
        width: var(--fw-tooltip\\icon-width);
        height: var(--fw-tooltip\\icon-height);

        &::before {
            position: absolute;
            content: '';
            border-color: transparent;
            border-style: solid;

        }
    }

    &[data-size='xs'] {
        font-size: 12px;
        --fw-tooltip\\padding: 3px 8px;
        --fw-tooltip\\border-radius: 3px;
    }
    &[data-size='sm'] {
        font-size: 12px;
        --fw-tooltip\\padding: 6px 10px;
        --fw-tooltip\\border-radius: 4px;
    }
    &[data-size='md'] {
        font-size: 12px;
        --fw-tooltip\\padding: 8px 12px;
        --fw-tooltip\\border-radius: 6px;
    }
    &[data-size='lg'] {
        font-size: 14px;
        --fw-tooltip\\padding: 10px 14px;
        --fw-tooltip\\border-radius: 6px;
    }


    &[data-placement="top"] {
        padding: var(--fw-tooltip\\icon-offset) 0;
        margin: var(--fw-tooltip\\icon-space) 0;

        @include slot('arrow') {
            bottom: 0;

            &::before {
                top: 0;
                border-width: var(--fw-tooltip\\icon-height) calc(var(--fw-tooltip\\icon-width) * .5) 0;
                border-top-color: var(--fw-tooltip\\background-color);
            }
        }
    }

    &[data-placement="right"] {
        padding: 0 var(--fw-tooltip\\icon-offset);
        margin: 0 var(--fw-tooltip\\icon-space);

        @include slot('arrow') {
            left: 0;
            width: var(--fw-tooltip\\icon-height);
            height: var(--fw-tooltip\\icon-width);

            &::before {
                right: 0;
                border-width: calc(var(--fw-tooltip\\icon-width) * .5) var(--fw-tooltip\\icon-height) calc(var(--fw-tooltip\\icon-width) * .5) 0;
                border-right-color: var(--fw-tooltip\\background-color);
            }
        }
    }

    &[data-placement="bottom"] {
        padding: var(--fw-tooltip\\icon-offset) 0;
        margin: var(--fw-tooltip\\icon-space) 0;

        @include slot('arrow') {
            top: 0;

            &::before {
                bottom: 0;
                border-width: 0 calc(var(--fw-tooltip\\icon-width) * .5) var(--fw-tooltip\\icon-height);
                border-bottom-color: var(--fw-tooltip\\background-color);
            }
        }
    }

    &[data-placement="left"] {
        padding: 0 var(--fw-tooltip\\icon-offset);
        margin: 0 var(--fw-tooltip\\icon-space);

        @include slot('arrow') {
            right: 0;
            width: var(--fw-tooltip\\icon-height);
            height: var(--fw-tooltip\\icon-width);

            &::before {
                left: 0;
                border-width: calc(var(--fw-tooltip\\icon-width) * .5) 0 calc(var(--fw-tooltip\\icon-width) * .5) var(--fw-tooltip\\icon-height);
                border-left-color: var(--fw-tooltip\\background-color);
            }
        }
    }
}

@include slot('content') {
    max-width: 400px;
    padding: var(--fw-tooltip\\padding, 16px);
    margin-left: var(--fw-tooltip\\offset-left, 0);
    margin-right: var(--fw-tooltip\\offset-right, 0);
    text-align: center;
    color: #f4f4f4;
    background-color: var(--fw-tooltip\\background-color);
    border: 1px solid #2F333B;
    border-radius: var(--fw-tooltip\\border-radius, 4px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
