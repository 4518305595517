@use 'sass:list';
@use '~Scss/core' as *;
@use '~Scss/core/scoped.scss' as * with (
    $scope: 'modal'
);



// Modal
:root {
    --modal__border-radius: 20px;
    --modal__padding-x: 40px;
    --modal__padding-y: 40px;
}


@include slot('root', 'backdrop') {
    position: fixed;
    z-index: 10000;
    outline: 0;
    //transition-duration: 150ms;
    //transition-timing-function: ease-in-out;
}


@include slot('backdrop') {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 0, 0, .5);

    &._show {
        opacity: 0.5;
    }
}


.fw-modal-open {
    overflow: hidden;
}

//
// ROOT
//
@include slot('root') {
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;

    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;

    pointer-events: none;


    &._show {
        opacity: 1;
    }

    &[data-fullscreen] {
        background-color: #000;

        &.fade {
            transform: scale(0);
            transition-property: transform, opacity, visibility;
            &.show {
                transform: scale(1);
            }
        }
    }

    &[data-scrollable] {
        overflow: hidden;;
    }
}


//
// DIALOG
//
@include slot('dialog') {
    --modal__border-radius: var(--border-radius, 16px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    padding: 0;
    margin: auto;
    width: var(--modal__width, 100%);
    min-width: var(--modal__min-width, initial);
    max-width: var(--modal__man-width, 100%);

    outline: 0;
    border-radius: var(--modal__border-radius, 16px);
    background: var(--fw-color-white);
    background-clip: padding-box;
    box-shadow: 0 24px 24px 0 rgba(30, 43, 107, 0.12);
    pointer-events: auto;

    & > div:first-child {
        border-top-left-radius: var(--modal__border-radius);
        border-top-right-radius: var(--modal__border-radius);
    }

    & > div:last-child {
        border-bottom-left-radius: var(--modal__border-radius);
        border-bottom-right-radius: var(--modal__border-radius);
    }
}


//
// BODY
//
@include slot('body') {
    --body-padding: 40px;
    --modal__padding-x: var(--body-padding);
    --modal__padding-y: var(--body-padding);

    overflow-y: auto;
    flex: 1 1 auto;
    position: relative;
    padding: var(--modal__padding-y) var(--modal__padding-x);
}


//
// HEADER
//
@include slot('header') {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    min-height: 77px;
    padding: 21px 30px 20px;
    background: var(--fw-color-dark-50);
}


//
// FOOTER
//
@include slot('footer') {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    border-top: 1px solid var(--fw-color-dark-50);
    padding: 20px 40px;

    // borderBottomLeftRadius: 'var(--border-radius, 16px)',
    // borderBottomRightRadius: 'var(--border-radius, 16px)',
}


//
// trigger-close
//
@include slot('closeTrigger') {
    margin: -20px;
    margin-left: auto;
    padding: 20px;

    width: 1em;
    height: 1em;
    box-sizing: content-box;

    font-size: 24px;
    font-weight: 700;
    line-height: 1;

    border: 0;
    color: #000;
    background-color: transparent;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;

    &:hover {
        opacity: 0.75;
    }
}
