@use 'sass:list';
@use '~Scss/bootstrap' as *;
@use '~Scss/core/scoped.scss' as * with (
    $scope: 'carousel'
);

@mixin _animate($properties, $duration: var(--fw-carousel--transition-duration)) {
    @include fw-animate($properties, $duration, var(--fw-carousel--transition-function))
}

:root {
    --fw-carousel--border-radius: var(--fw-dropzone-area-border-radius, 0);

    //
    // Transition
    --fw-carousel--transition-duration: 300ms;
    --fw-carousel--transition-function: ease-in-out;

    //
    // Control
    --fw-carousel-control--color: #fff;
    --fw-carousel-control--width: 15%;
    --fw-carousel-control--hover-opacity: .9;

    //
    // Indicator
    :root {
        --fw-carousel-indicator--width: 30px;
        --fw-carousel-indicator--height: 15px;
        --fw-carousel-indicator--active-bg: #fff;
        --fw-carousel-indicator--opacity: .5;
    }
}

@include slot('root') {
    position: relative;
    pointer-events: initial;
    height: var(--fw-carousel--height, auto);

    &[data-fullscreen='true'] {
        height: 100%;
    }

    &:is(:focus, :focus-within) {
        outline: none;
        border: none;
    }
}

@include slot('inner') {
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    @include clearfix();
}

@include slot('item') {
    float: left;
    margin-right: -100%;
    backface-visibility: hidden;
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    @include _animate((transform, opacity), '300ms');

    &:not([aria-current="true"], [data-transition]) {
        display: none;
    }

    &:is(
            [data-transition="in"]:not([data-slide="left"]),
            [aria-current="true"][data-slide="right"]
        ) {
        transform: translateX(100%);
    }

    &:is(
            [data-transition="out"]:not([data-slide="right"]),
            [aria-current="true"][data-slide="left"]
        ) {
        transform: translateX(-100%);
    }
}


//
// Actions
//
@include slot('actions') {
    position: absolute;
    z-index: 120;
    bottom: 20px;
    right: 20px;

    display: flex;
    justify-content: flex-end;
    transition: opacity ease-in-out 300ms;

    box-shadow: 0 2px 12px 0 rgba(30, 43, 107, 0.6);
    border-radius: var(--fw-carousel--border-radius, 0);
    opacity: var(--fw-carousel-icon--opacity, .8);
    --fw-btn-background: #ffffffcc;

    &:is(:hover, :focus, :focus-within) {
        --fw-btn-background: #ffffffff;
        opacity: var(--fw-carousel-icon--opacity-hover, 1);
    }
}


//
// Trigger
//
@include scope() {
    &[data-part^='trigger-'] {
        position: absolute;
        z-index: 100;
        cursor: pointer;

        padding: 0;
        border: 0;
        background: none;

        color: var(--fw-carousel-control--color);

        @include _animate((opacity));

        .fw-icon {
            pointer-events: all;
            font-size: 80px;
            color: rgba(255, 255, 255, 1);
            opacity: var(--fw-carousel-icon--opacity, .8);
            stroke-width: 1.5;
            filter: drop-shadow(0 0 2px rgba(0, 0, 0, .4));

            transition-property: opacity, color, border, background-color, transform, stroke-width, box-shadow;
            transition-timing-function: ease-in-out;
            transition-duration: 300ms;
        }

        &:hover {
            --fw-carousel-icon--opacity: 1;
            color: var(--fw-carousel-control--color);
            text-decoration: none;

            .fw-icon{
                stroke-width: 1;
                opacity: var(--fw-carousel-icon--opacity-hover, 1);
                transform: scale(var(--fw-carousel-icon--scale, 1.3));
            }
        }

        &:is(:focus, :focus-within) {
            outline: none;
            border: none;
        }
    }


    @include part('trigger-fullscreen') {
        z-index: 80;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;;
        align-items: center;
        justify-content: center;

        border-radius: var(--fw-carousel--border-radius, 0);

        transition-timing-function: ease-in-out;
        transition: background-color ease-in-out 300ms, opacity ease-in-out 300ms;

        .fw-icon {
            --fw-carousel-icon--scale: 1.1;
        }

        &:hover {
            --fw-carousel-icon--opacity: .8;

            .fw-icon {
                stroke-width: 1;
            }
        }
    }

    @include part('trigger-prev', 'trigger-next') {
        top: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: var(--fw-carousel-control--width);
    }

    @include part('trigger-prev') {
        left: 0;
        justify-content: start;
    }

    @include part('trigger-next') {
        right: 0;
        justify-content: end;
    }
}


//
// Indicators
//
@include slot('indicator-group', 'pagination') {
    position: absolute;
    z-index: 105;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    list-style: none;
    display: inline-flex;
    gap: 0;
    padding: 0 0 20px;
    margin: 0;
    justify-content: center;
    opacity: 0;

    @include _animate((opacity));

    *:is(:hover, :focus, :focus-within) > & {
        opacity: 1;
    }
}

@include slot('pagination') {
    ol {
        > li {
            flex: 0 1 auto;
            width: var(--fw-carousel-indicator--width);
            height: var(--fw-carousel-indicator--height);
            margin: 0;
            text-indent: -999px;

            cursor: pointer;

            padding: 10px 5px;
            box-sizing: content-box;
            opacity: var(--fw-carousel-indicator--opacity);

            @include _animate((opacity, background-color));

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: var(--fw-carousel-indicator--active-bg);
                background-clip: padding-box;
                overflow: hidden;

                border-radius: 2px;
                border: 1px solid rgba(50, 50, 50, .4);
                box-shadow: 0 0 13px 0 rgba(255, 255, 255, .6);

                @include _animate((opacity, background-color));
            }

            &:not([aria-current='true']):hover {
                opacity: var(--fw-carousel-control--hover-opacity);
            }

            &[aria-current='true'] {
                opacity: 1;
            }
        }
    }
}
