
// breakpoints
$fw-media-breakpoints: (
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 576px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 1024px, //1024px,
    // Extra large screen / wide desktop
    xl: 1399px
) !default;


@function fw-media-breakpoint($type) {
    @if (fw-has($fw-media-breakpoints, $type)) {
        @return fw-get($fw-media-breakpoints, $type);
    } @else {
        @return $type;
    }
}


//
// Media breakpoints
//
@mixin fw-media-down($width) {
    @media (max-width: #{fw-media-breakpoint($width)}) {
        @content;
    }
}

@mixin fw-media-up($width) {
    @media (min-width: #{fw-media-breakpoint($width) + 1px}) {
        @content;
    }
}

@mixin fw-media-between($from, $to) {
    @media (min-width: #{fw-media-breakpoint($from) + 1px}) and (max-width: #{fw-media-breakpoint($to)}) {
        @content;
    }
}


//
// desktop
//
@mixin fw-desktop {
    @include fw-media-up(lg) {
        @content;
    }
}

@mixin fw-desktop-xl {
    @include fw-media-up(xl) {
        @content;
    }
}

@mixin fw-desktop-xxl {
    @include fw-media-up(xxl) {
        @content;
    }
}

@mixin fw-desktop-and-tablet {
    @include fw-media-up(md) {
        @content;
    }
}

@mixin fw-minimal-desktop {
    @include fw-media-between(lg, xl) {
        @content;
    }
}

@mixin fw-minimal-desktop-and-below {
    @include fw-media-down(xl) {
        @content;
    }
}


//
// tablet
//
@mixin fw-tablet {
    @include fw-media-between(md, lg) {
        @content;
    }
}

@mixin fw-tablet-and-mobile {
    @include fw-media-down(lg) {
        @content;
    }
}


//
// mobile
//
@mixin fw-mobile {
    @include fw-media-down(md) {
        @content;
    }
}

@mixin fw-mobile-sm {
    @include fw-media-down(sm) {
        @content;
    }
}
