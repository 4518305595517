@use "~Scss/core" as c;


@mixin menu-btn(
    $color: null,
    $background-color: null,
    $background-opacity: null,
    $box-shadow: null,
    $border-color: null
) {
    @if $color != null {
        color: $color;
    }

    @if $border-color != null {
        border-color: $border-color;
    }

    &:before {
        @if $background-color != null {
            background-color: $background-color;
        }
        @if $background-opacity != null {
            opacity: $background-opacity;
        }
    }
}

@mixin menu-item {
    @extend %fw-menu-item;
    @content;
}

%fw-menu-item {
    position: relative;
    border-color: transparent;
    border-width: 0; // For `<button>`s
    background-color: transparent !important;

    &,
    &:before {
        @include c.border-radius(var(--menu-border-radius));
    }

    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 1;
    }

    .fw-icon {
        font-size: calc(1em + 4px);
    }

}
